import React from 'react';
import { Alert as AntdAlert, Typography as AntdTypography } from 'antd';
import { MainleveeResponse } from 'hooks/dossiers/useFetchDonneesMainlevee';

interface MainLeveeBannerProps {
  mainlevee: MainleveeResponse;
}

const MainLeveeBanner = ({
  mainlevee,
}: MainLeveeBannerProps): React.ReactElement => {
  if (!mainlevee?.acheteur) {
    return <></>;
  }
  return (
    <AntdAlert
      message={
        <div>
          <AntdTypography>
            Ce véhicule a été vendu par le service des Domaines.
          </AntdTypography>
          <AntdTypography>
            L&apos;identité de l&apos;acheteur - autorisé à récupérer le
            véhicule - est la suivante :
          </AntdTypography>
          <ul>
            {mainlevee?.acheteur?.prenom && (
              <li>
                <AntdTypography>
                  Prénom : {mainlevee?.acheteur?.prenom}
                </AntdTypography>
              </li>
            )}
            {mainlevee?.acheteur?.nom && (
              <li>
                <AntdTypography>
                  Nom : {mainlevee?.acheteur?.nom}
                </AntdTypography>
              </li>
            )}
            {mainlevee?.acheteur?.raisonSociale && (
              <li>
                <AntdTypography>
                  Raison sociale : {mainlevee?.acheteur?.raisonSociale}
                </AntdTypography>
              </li>
            )}
          </ul>
        </div>
      }
      showIcon
    />
  );
};

export default MainLeveeBanner;
