import React, { ReactElement, useEffect, useState } from 'react';
import { TableProps as AntdTableProps } from 'antd/lib/table';
import { MenuOutlined } from '@ant-design/icons';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  OnDragEndResponder,
} from 'react-beautiful-dnd';

import { Empty } from '../../Empty';
import {
  ColumnProps,
  FetchDataFunctionWithoutArgument,
  FetchDataResult,
} from '../types';
import { BasicTable } from 'components/WrappedComponents/Table';
import { DataTypeWithId } from './types';

import './DragSortableTable.less';

interface DragSortableTableProps<DataType extends DataTypeWithId> {
  // Table - general
  columns: ColumnProps<DataType>[];
  fetchData?: FetchDataFunctionWithoutArgument<DataType>;
  disabled?: boolean;
  buildItemKey: (item: DataType) => string | number;
  antdTableProps?: Partial<AntdTableProps<DataType>>;
  onDragEnd: (ordered: DataType[]) => void;
}

/**
 * Table component to display a large amount of data. This component provide a table that enable drag and drop sorting.
 *
 * @param columns - Paramters to represent columns used in this Table. Each column is
 *        associated with a single attribute of fetched items
 * @param fetchData - Function called to fetch data (either sync or async). This function is
 *        supposed to return a list of fetched object corresponding to those filters..
 * @param buildItemKey - Function to build a unique key for each item. Required when
 *        using item seection.
 * @param antdTableProps - To use other props defined by Antd library on Table component
 *        (/!\ may interfere with props already defined by this component).
 * @param onDragEnd - function to play after drag an element
 */
function DragSortableTable<DataType extends DataTypeWithId>({
  // Table - general
  columns,
  fetchData,
  //other
  disabled,
  buildItemKey,
  antdTableProps,
  onDragEnd,
}: DragSortableTableProps<DataType>): React.ReactElement {
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [fetchedData, setFetchedData] = useState<
    FetchDataResult<DataType> | undefined
  >(undefined);
  const [isOnError, setIsOnError] = useState<boolean>(false);

  // This variable is increase each time we want to re-fetch data
  const [triggerUpdate, setTriggerUpdate] = useState<number>(0);

  const onSortEnd = ({
    source: { index: oldIndex },
    destination,
  }: DropResult) => {
    const newIndex = destination?.index;
    if (newIndex !== undefined && oldIndex !== newIndex && onDragEnd) {
      if (fetchedData?.data) {
        const newFetchedData = { ...fetchedData };
        const newData: DataType[] = [...newFetchedData.data];
        newData.splice(oldIndex, 1);
        newData.splice(newIndex, 0, newFetchedData.data[oldIndex]);
        newFetchedData.data = newData;
        setFetchedData(newFetchedData);
        onDragEnd(newFetchedData.data);
      }
    }
  };

  // When the parameters change, we recall the function to retrieve corresponding data
  useEffect(() => {
    let isOutdated = false;
    // Fetch new data
    const loadNewData = async function loadNewData(): Promise<void> {
      try {
        if (fetchData !== undefined) {
          setIsLoadingData(true);
          const data = await fetchData();
          if (!isOutdated) {
            setFetchedData(data);
            setIsOnError(false);
            setIsLoadingData(false);
          }
        }
      } catch (e) {
        if (!isOutdated) {
          setFetchedData({ data: [], total: 0 });
          setIsOnError(true);
          setIsLoadingData(false);
        }
      }
    };
    void loadNewData();
    return (): void => {
      isOutdated = true;
    };
  }, [triggerUpdate, fetchData]);

  if (disabled === true) {
    return (
      <>
        <BasicTable
          data={fetchedData !== undefined ? fetchedData.data : []}
          columnsProps={columns}
          isLoading={isLoadingData}
          reload={() => setTriggerUpdate(val => ++val)}
          isOnError={isOnError}
          antdTableProps={{
            ...antdTableProps,
            className: 'DragSortableTable',
            pagination: false,
          }}
        />
      </>
    );
  } else {
    // in order to be able to do the drag n drop, we add the DragHandle to the columns
    if (!columns.find(c => c.dataIndex === 'sort')) {
      columns = [
        {
          title: '',
          key: 'sort',
          width: 40,
          buildCellContent: () => (
            <MenuOutlined style={{ color: '#999', marginLeft: 10 }} />
          ),
        },
        ...columns,
      ];
    }

    return (
      <>
        <BasicTable
          data={fetchedData !== undefined ? fetchedData.data : []}
          columnsProps={columns}
          isLoading={isLoadingData}
          reload={() => setTriggerUpdate(val => ++val)}
          isOnError={isOnError}
          buildItemKey={buildItemKey}
          antdTableProps={{
            ...antdTableProps,
            className: 'DragSortableTable',
            rowKey: 'id',
            pagination: false,
            components: {
              body: {
                wrapper: (
                  val: React.DetailedHTMLProps<
                    React.HTMLAttributes<HTMLTableSectionElement>,
                    HTMLTableSectionElement
                  >,
                ) => DroppableTableBody({ ...val, onSortEnd }),
                row: (val: DraggableTableRowProps<DataType>) =>
                  DraggableTableRow({ ...val, data: fetchedData }),
              },
            },
          }}
        />
      </>
    );
  }
}

export default DragSortableTable;

interface DroppableTableBodyProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableSectionElement>,
    HTMLTableSectionElement
  > {
  onSortEnd: OnDragEndResponder;
}

function DroppableTableBody({
  onSortEnd,
  ...props
}: DroppableTableBodyProps): ReactElement {
  return (
    <DragDropContext onDragEnd={onSortEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <tbody
            ref={provided.innerRef}
            {...props}
            {...provided.droppableProps}
            className={props.className}
          ></tbody>
        )}
      </Droppable>
    </DragDropContext>
  );
}

interface DraggableTableRowProps<DataType>
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
  > {
  index: number;
  data: FetchDataResult<DataType> | undefined;
  'data-row-key': string;
}

function DraggableTableRow<DataType extends DataTypeWithId>({
  data,
  ...props
}: DraggableTableRowProps<DataType>): ReactElement {
  if (!data?.total) {
    return (
      <tr {...props}>
        <td>
          <div>
            <Empty />
          </div>
        </td>
      </tr>
    );
  }

  const index = data?.data.findIndex((x: DataType) => {
    return x.id === props['data-row-key'];
  });

  return (
    <Draggable
      key={props['data-row-key']}
      draggableId={props['data-row-key'].toString()}
      index={index}
    >
      {(provided, snapshot) => {
        return (
          <tr
            ref={provided.innerRef}
            {...props}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={`row-item ${snapshot.isDragging ? 'row-dragging' : ''}`}
          ></tr>
        );
      }}
    </Draggable>
  );
}
