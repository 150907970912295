import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { useDossierAdminContext } from 'hooks/dossierAdmin/DossierAdminContext';
import AsyncComponent from 'components/AsyncComponent/AsyncComponent';
import ActionsSuiviVehicule from '../Actions/ActionsSuiviVehicule';
import { ErrorHeader } from './ErrorHeader/ErrorHeader';

import './FicheHeader.less';

export const FicheHeader = (): React.ReactElement => {
  const navigate = useNavigate();
  const [
    ,
    {
      events: dossierEvents,
      fetchEvents: fetchEvents,
      errorOccured: errorOccured,
    },
  ] = useDossierAdminContext();

  return (
    <div className="FicheHeaderContainer">
      <div className="LeftFicheAdmin">
        <Button
          className="return-button"
          icon={<ArrowLeftOutlined />}
          onClick={(): void => navigate(-1)}
          type="default"
          shape="round"
          size="middle"
        >
          {'Retour'}
        </Button>
      </div>
      <div className="CenterFicheAdmin">
        <AsyncComponent
          errorOccured={errorOccured}
          inProgress={false}
          reload={fetchEvents}
          render={() => {
            return dossierEvents.some(event => event.isIncident) ? (
              <ErrorHeader />
            ) : null;
          }}
          errorMessage="Impossible de charger les données relatives aux évènements"
        />
      </div>
      <div className="RightFicheAdmin">
        <ActionsSuiviVehicule />
      </div>
    </div>
  );
};
