import React, { ReactElement } from 'react';
import { Button as AntdButton } from 'antd';
import {
  DossierResponseDto,
  RelanceVenteDtoRelanceVenteTypeEnum,
} from 'lib_api/lib/api/gen';
import { useApi } from 'hooks/ApiStoreContext';
import { useDossierContext } from 'hooks/dossiers/DossierContext';
import { useRelanceVente } from 'hooks/dossiers/useRelanceVente';
import { extractNonnullDossierIdOrThrow } from 'utils/dossierUtils';
import { extractStatutAlienationFromDossierOrThrow } from 'utils/alienationUtils';
import './ControleBlocageVente.less';

interface ControleBlocageVenteProps {
  close: () => void;
}

const ControleBlocageVente = ({
  close,
}: ControleBlocageVenteProps): ReactElement => {
  const [dossier, setDossier] = useDossierContext();
  const dossierId = extractNonnullDossierIdOrThrow(dossier);
  const { RelanceVenteControllerApi } = useApi();
  const statutAlienation = extractStatutAlienationFromDossierOrThrow(dossier);
  const [submit, submitInProgress] = useRelanceVente(
    RelanceVenteControllerApi,
    dossierId,
    (updatedDossier: DossierResponseDto) => {
      setDossier(updatedDossier);
    },
    close,
    RelanceVenteDtoRelanceVenteTypeEnum.MANQUANT,
    statutAlienation,
  );

  return (
    <div className="controleBlocageVente">
      <h3>Contrôler le blocage de la vente</h3>
      <p>
        Le commissaire aux ventes des Domaines a bloqué la vente de ce véhicule
        considéré comme manquant.
      </p>
      <p>
        <b>
          Concrètement, il se peut que l’un des cas suivants se soit produit :
        </b>
      </p>
      <ul>
        <li>Le véhicule a subi une casse importante / est brûlé</li>
        <li>Le véhicule est introuvable / a été volé sur parc</li>
        <li>
          Le véhicule a été déplacé dans une autre fourrière par le gardien de
          fourrière
        </li>
      </ul>
      <p>
        <b>Afin de débloquer la situation :</b>
      </p>
      <ul>
        <li>
          clarifier la situation en contactant la fourrière concernée{' '}
          {dossier.computed?.fourriereDto?.raisonSociale}
          <ul>
            <li>
              Si le véhicule demeure introuvable ou a effectivement subi des
              dommages importants, inviter le gardien à sortir le véhicule du SI
              Fourrières dès lors qu’il n’est plus sur le parc.
            </li>
            <li>
              Si le véhicule est retrouvé et apte à la vente, cliquer sur «
              Relancer la vente » pour remettre à nouveau ce dossier aux
              Domaines.
            </li>
          </ul>
        </li>
      </ul>

      <p className="consequenceActionRelance">
        <span>
          <b>Conséquence de l’action « Relancer la vente »</b>
        </span>
        <br />
        <span>
          Au clic sur « Relancer la vente », le dossier sera remis aux Domaines
          pour une nouvelle tentative de vente.
          <br></br>
          Si le véhicule n’est pas retrouvé ou si son état ne peut permettre une
          remise en vente, la sortie du véhicule doit être effectuée par le GF,
          cliquer sur « Annuler » pour clôturer le contrôle.
          <br></br>
          En cliquant sur « Relancer la vente », vous confirmez que le véhicule
          est présent dans la fourrière identifiée et apte à la vente.
        </span>
      </p>
      <div className="buttonsContainer">
        <AntdButton onClick={close}>Annuler</AntdButton>
        <AntdButton type="primary" disabled={submitInProgress} onClick={submit}>
          Relancer la vente
        </AntdButton>
      </div>
    </div>
  );
};

export default ControleBlocageVente;
