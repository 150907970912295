import {
  DossierDtoAdminFonctionnel,
  DossierDtoAdminFonctionnelActionsRequisesEnum,
  RelanceVenteDtoRelanceVenteTypeEnum,
} from 'lib_api/lib/api/gen';

import {
  backAlertMessage,
  openNotification,
} from 'hooks/utils/backAlertMessage';
import { useBoolean } from 'utils/genericUtils';
import { useApi } from 'hooks/ApiStoreContext';

const availableRelanceVenteTypes: Partial<{
  [key in DossierDtoAdminFonctionnelActionsRequisesEnum]: RelanceVenteDtoRelanceVenteTypeEnum;
}> = {
  RELANCE_VENTE_IMMO_PJ: RelanceVenteDtoRelanceVenteTypeEnum.IMMO_PJ,
  RELANCE_VENTE_OPPOSITION: RelanceVenteDtoRelanceVenteTypeEnum.OPPOSITION,
  RELANCE_VENTE_DOUBLON: RelanceVenteDtoRelanceVenteTypeEnum.DOUBLON,
};

export function useRelanceVente(
  dossier: DossierDtoAdminFonctionnel,
  setDossier: (dossier: DossierDtoAdminFonctionnel) => void,
  fetchEvents: () => Promise<void>,
  closeModal: () => void,
): [() => void, boolean] {
  const { AdminFonctionnelControllerApi: controller } = useApi();
  const {
    value: inProgress,
    setIsTrue: setInProgress,
    setIsFalse: setNotInProgress,
  } = useBoolean(false);

  const submitRelanceVente = () => {
    setInProgress();
    const actionRequise = dossier.actionsRequises?.find(action =>
      Object.keys(availableRelanceVenteTypes).includes(action),
    );
    const relanceType = actionRequise
      ? availableRelanceVenteTypes[actionRequise]
      : null;
    if (!relanceType) {
      openNotification('Impossible de relancer la vente', {
        description:
          "Le dossier doit comporter l'une des actions suivantes " +
          Object.keys(availableRelanceVenteTypes).reduce(
            (first, second) => `${first}, ${second}`,
          ),
      });
      setNotInProgress();
      return;
    }

    controller
      .relanceVenteAdminUsingPOST({
        dossierId: dossier.id,
        relanceVenteType: relanceType,
        statut: null,
      })
      .then(setDossier)
      .then(closeModal)
      .then(fetchEvents)
      .catch(backAlertMessage)
      .finally(setNotInProgress);
  };

  return [submitRelanceVente, inProgress];
}
