import { DossierResponseDto } from 'lib_api/lib/api/gen';

export function extractStatutAlienationFromDossierOrThrow(
  dossier: DossierResponseDto,
): string {
  const statutAlienation = dossier.body?.alienationInformationDto?.statut;
  if (statutAlienation === undefined || statutAlienation === null) {
    throw new Error('Dossier should have an alienation status defined');
  }
  return statutAlienation;
}
