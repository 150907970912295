import React, { useState } from 'react';
import { Modal as AntdModal } from 'antd';
import ControleErreurIdentificationVehiculeForm from './ControleErreurIdentificationVehiculeForm';
import ControleBlocageVenteInfos from './ControleBlocageVenteInfos';

interface ControleBlocageVenteErreurIdentificationVehiculeProps {
  visible?: boolean | undefined;
  close: () => void;
}

const ControleBlocageVenteErreurIdentificationVehicule = ({
  visible,
  close,
}: ControleBlocageVenteErreurIdentificationVehiculeProps): React.ReactElement => {
  const [index, setIndex] = useState(0);

  const onChangeIndex = (indexTab: number) => {
    setIndex(Math.min(1, Math.max(indexTab, 0)));
  };

  const nextStep = () => {
    onChangeIndex(index + 1);
  };

  const previousStep = () => {
    onChangeIndex(index - 1);
  };

  const steps = [
    <ControleBlocageVenteInfos next={nextStep} close={close} key={0} />,
    <ControleErreurIdentificationVehiculeForm
      previous={previousStep}
      close={close}
      key={1}
    />,
  ];

  return (
    <AntdModal
      visible={visible}
      onCancel={close}
      footer={null}
      closable={true}
      destroyOnClose={true}
      width={800}
      maskClosable={false}
    >
      <div className="controleBlocageVente">{steps[index]}</div>
    </AntdModal>
  );
};

export default ControleBlocageVenteErreurIdentificationVehicule;
