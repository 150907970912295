import React, { useCallback } from 'react';
import { Moment } from 'moment';
import { Checkbox, Typography as AntdTypo } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { isEqual } from 'lodash';

import { TraitementResponseDtoListStatutEnum } from 'lib_api/lib/api/gen';
import { FilterButton, SearchBar } from 'components/WrappedComponents';
import { StatutTraitementFacturation } from 'hooks/dossiers/useSearchDossierSummaries';
import {
  DashboardAdminFonctionnelFilter,
  DashboardAdminFonctionnelFilterFormValues,
} from '../../types';
import DashboardAdminFonctionnelFilterForm from './FilterFormAdminFonctionnel/DashboardAdminFonctionnelFilterForm';

interface FiltersProps {
  filter: Partial<DashboardAdminFonctionnelFilter>;
  setFilter: (value: Partial<DashboardAdminFonctionnelFilter>) => void;
}

function keepDossierSortis(
  statut: TraitementResponseDtoListStatutEnum[],
  sortieFilter: boolean,
  dateRangeSortieDefinitive: [Moment | undefined, Moment | undefined],
  statutsTraitementFacturation: StatutTraitementFacturation[],
  motifDeSortieFilter: string[],
  includeDossiersAnonymises: boolean,
): boolean {
  // automatically check the "sorti" checkbox
  return (
    // if the statut SORTI is selected
    statut.includes(TraitementResponseDtoListStatutEnum.SORTI) ||
    // if the checkbox is checked
    (statut.length === 0 && sortieFilter) ||
    // if we have traitement facturation values we need to include dossier SORTI
    statutsTraitementFacturation?.length > 0 ||
    // if we search for a date sortie
    !!dateRangeSortieDefinitive[0] ||
    !!dateRangeSortieDefinitive[1] ||
    // if we have filter on motif sortie
    motifDeSortieFilter.length !== 0 ||
    // if we search for anonymized dossiers
    includeDossiersAnonymises
  );
}

//add sortie status if we have a traitement facturation status and already specified statuses
function computeStatus(
  statut: TraitementResponseDtoListStatutEnum[],
  statutsTraitementFacturation: StatutTraitementFacturation[],
): TraitementResponseDtoListStatutEnum[] | undefined {
  if (
    statutsTraitementFacturation?.length > 0 &&
    statut?.length > 0 &&
    !statut.includes(TraitementResponseDtoListStatutEnum.SORTI)
  ) {
    statut.push(TraitementResponseDtoListStatutEnum.SORTI);
  }

  return statut.length > 0 ? statut : undefined;
}

function Filters({ filter, setFilter }: FiltersProps): React.ReactElement {
  const {
    search,
    includeDossiersSortis,
    includeOnlyNonTraiteFacturation,
    ...formFilter
  } = filter;

  //keep the checkbox checked only if the traitement facturation statuses contains only NON_TRAITE
  const keepOnlyNonTraiteFacturation = useCallback(
    (statutsTraitementFacturation?: StatutTraitementFacturation[]): boolean => {
      return isEqual(
        new Set(statutsTraitementFacturation),
        new Set(StatutTraitementFacturation.NON_TRAITE),
      );
    },
    [],
  );

  function setFormFilter(
    value: Partial<DashboardAdminFonctionnelFilterFormValues>,
  ) {
    const {
      statut,
      dateRangeSortieDefinitive,
      statutsTraitementFacturation,
      motifDeSortie,
      includeDossiersAnonymises,
    } = value;
    setFilter({
      ...filter,
      ...value,
      includeDossiersSortis: keepDossierSortis(
        statut ?? [],
        filter.includeDossiersSortis ?? false,
        dateRangeSortieDefinitive ?? [undefined, undefined],
        statutsTraitementFacturation ?? [],
        motifDeSortie ?? [],
        includeDossiersAnonymises ?? false,
      ),
      includeOnlyNonTraiteFacturation: keepOnlyNonTraiteFacturation(
        statutsTraitementFacturation ?? [],
      ),
      statut: computeStatus(statut ?? [], statutsTraitementFacturation ?? []),
    });
  }

  function setIncludeDossiersSortis(event: CheckboxChangeEvent) {
    const { statut, dateRangeSortieDefinitive, motifDeSortie } = filter;
    const updatedStatut =
      statut?.filter(s => s !== TraitementResponseDtoListStatutEnum.SORTI) ||
      [];

    // Only add the SORTI statut filter if the statut filter is not empty
    if (event.target.checked && (statut?.length ?? -1) > 0) {
      updatedStatut.push(TraitementResponseDtoListStatutEnum.SORTI);
    }

    setFilter({
      ...filter,
      statut: updatedStatut,
      includeDossiersSortis: event.target.checked,
      // clear the date sortie filter when checkbox unchecked
      dateRangeSortieDefinitive: event.target.checked
        ? dateRangeSortieDefinitive
        : undefined,
      motifDeSortie: event.target.checked ? motifDeSortie : [],
      includeDossiersAnonymises: undefined,
    });
  }

  function setIncludeOnlyNonTraiteFacturation(event: CheckboxChangeEvent) {
    const isChecked = event.target.checked;
    const { statut } = filter;
    const updatedStatut =
      statut?.filter(s => s !== TraitementResponseDtoListStatutEnum.SORTI) ||
      [];

    // Only add the SORTI statut filter if the statut filter is not empty
    if (event.target.checked && (statut?.length ?? -1) > 0) {
      updatedStatut.push(TraitementResponseDtoListStatutEnum.SORTI);
    }

    if (isChecked) {
      setIncludeDossiersSortis(event);
    }

    setFilter({
      ...filter,
      statut: isChecked ? updatedStatut : filter.statut,
      statutsTraitementFacturation: isChecked
        ? [StatutTraitementFacturation.NON_TRAITE]
        : undefined,
      includeDossiersSortis: isChecked
        ? isChecked
        : filter.includeDossiersSortis,
      includeOnlyNonTraiteFacturation: isChecked,
    });
  }

  return (
    <div className="filters-container">
      <SearchBar
        value={filter.search}
        // Plates values are forced to uppercase
        setValue={search =>
          setFilter({ ...filter, search: search?.toUpperCase() })
        }
        antdProps={{
          placeholder: 'Rechercher une plaque...',
          style: { maxWidth: 300 },
        }}
        rounded={true}
      />
      <FilterButton
        filter={formFilter}
        setFilter={setFormFilter}
        textInactiveFilter="Filtres"
        textActiveFilter="Filtres (actifs)"
      >
        {(setData, data) => (
          <>
            <AntdTypo.Title level={3}>Filtres</AntdTypo.Title>
            <DashboardAdminFonctionnelFilterForm
              filter={data}
              setFilter={setData}
            />
          </>
        )}
      </FilterButton>
      <Checkbox
        checked={includeDossiersSortis}
        onChange={setIncludeDossiersSortis}
      >
        Afficher les véhicules sortis
      </Checkbox>
      <Checkbox
        checked={includeOnlyNonTraiteFacturation}
        onChange={setIncludeOnlyNonTraiteFacturation}
      >
        Afficher uniquement les véhicules non traités en facturation
      </Checkbox>
    </div>
  );
}

export default Filters;
