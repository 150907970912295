import { useState, useEffect } from 'react';

import { MarqueDto } from 'lib_api/lib/api/gen';

import { useApi } from '../ApiStoreContext';
import { useHandleBackErrors } from '../utils/handleBackErrors';

export const useFetchMarque = (
  marqueId: string | null,
): [MarqueDto | null, boolean, boolean] => {
  const behaviourOnError = useHandleBackErrors();
  const [marque, setMarque] = useState<MarqueDto | null>(null);
  const referentielControllerApi = useApi().ReferentielControllerApi;
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [errorOccured, setErrorOccured] = useState<boolean>(false);

  useEffect(() => {
    if (marqueId) {
      setInProgress(true);
      referentielControllerApi
        .getMarqueByIdUsingGET(marqueId)
        .then(setMarque)
        .catch(error => {
          behaviourOnError(error);
          setErrorOccured(true);
        })
        .finally(() => setInProgress(false));
    }
  }, [referentielControllerApi, behaviourOnError, marqueId]);
  return [marque, inProgress, errorOccured];
};
