import React from 'react';

import Actions from 'components/ActionAndFileWrapper/Actions/Actions';
import AvailableFiles from 'components/ActionAndFileWrapper/AvailableFiles/AvailableFiles';
import { ProcessInstanceUrl } from 'hooks/dossierAdmin/useFetchProcessInstances';

import { ActionAndFileWrapperType } from './ActionAndFileWrapper';
import AvailableProcessInstances from './AvailableProcessInstances/AvailableProcessInstances';

import './ActionAndFileWrapper.less';

interface ActionAndFileWrapperAdminType extends ActionAndFileWrapperType {
  processInstances: ProcessInstanceUrl[];
}

const ActionAndFileWrapperAdmin = ({
  filesList,
  actions,
  dossierId,
  processInstances,
}: ActionAndFileWrapperAdminType): React.ReactElement => {
  return (
    <div className={'ActionsContainer'}>
      {!!processInstances.length && (
        <AvailableProcessInstances processInstances={processInstances} />
      )}
      {!!filesList.length && (
        <AvailableFiles files={filesList} dossierId={dossierId} />
      )}
      {!!actions.length && <Actions actions={actions} />}
    </div>
  );
};

export default ActionAndFileWrapperAdmin;
