import React, { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { TableSort } from 'components/WrappedComponents/Table/types';

import AFFilterProvider from 'hooks/AFFilterStoreContext';
import PaginationProvider from 'hooks/PaginationStoreContext';
import { PAGINATION_DEFAULT } from 'utils/DashboardUtils';
import { Statistiques } from 'pages/autorite-fourriere/Statistiques';
import {
  AutoriteFourriereSortOptions,
  DashboardAutoriteFourriereFilter,
} from 'pages/autorite-fourriere/Dashboard/types';
import { NOT_FOUND_PATH } from 'utils/enumData';
import SortProvider from 'hooks/SortStoreContext';
import { useActionsRequises } from 'hooks/useActionsRequises';
import { Details } from './Dossier/Details/Details';
import DashBoard from './Dashboard';

interface TablePagination {
  page: number;
  pageSize: number;
}

const AfRouter: React.FC = () => {
  const [pagination, setPagination] =
    useState<TablePagination>(PAGINATION_DEFAULT);
  const [filter, setFilter] = useState<
    Partial<DashboardAutoriteFourriereFilter>
  >({});
  const [sort, setSort] = useState<
    Partial<TableSort<AutoriteFourriereSortOptions | undefined>>
  >({
    IMMATRICULATION: true,
  });

  const afActionsRequises = useActionsRequises();

  return (
    <SortProvider value={{ sort: sort, setSort: setSort }}>
      <PaginationProvider
        value={{ pagination: pagination, setPagination: setPagination }}
      >
        <AFFilterProvider
          value={{
            filter: filter,
            setFilter: setFilter,
            actionsRequises: afActionsRequises,
          }}
        >
          <Routes>
            <Route path="" element={<DashBoard />} />
            <Route path="dossier/:id" element={<Details />} />
            <Route path="statistiques" element={<Statistiques />} />
            <Route
              path="*"
              element={<Navigate replace to={NOT_FOUND_PATH} />}
            />
          </Routes>
        </AFFilterProvider>
      </PaginationProvider>
    </SortProvider>
  );
};

export default AfRouter;
