import React, { useEffect, useState } from 'react';
import {
  ColProps as AntdColProps,
  Form as AntdForm,
  FormItemProps as AntdFormItemProps,
  Tooltip as AntdTooltip,
  TooltipProps as AntdTooltipProps,
} from 'antd';
import { NamePath } from 'antd/lib/form/interface';

import { isValueDefined } from 'components/WrappedComponents/Form/FormFields/utils';
import { CSSUtils } from 'utils/CSSUtils';
import { useBaseFormContext } from './BaseFormContext';
import { setFieldValue } from './utils';
import { RenderProps } from './types';
import BaseInput from './BaseInput';

interface FormItemProps<FormValues, FormField>
  extends Omit<AntdFormItemProps<FormValues>, 'required'> {
  field?: FormField;
  name: keyof FormValues & string & NamePath<FormValues>;
  required?: boolean | ((values: FormValues) => boolean);
  disabled?: boolean | ((values: FormValues) => boolean);
  displayItem?: (values: FormValues) => boolean;
  render?: (props: RenderProps<FormValues>) => React.ReactElement;
  renderBeforeInput?: (values: FormValues) => React.ReactElement;
  renderAfterInput?: (values: FormValues) => React.ReactElement;
  formValues: FormValues;
  validateField?: (field: FormField, value: FormValues) => Promise<void>;
  labelCol?: AntdColProps;
  wrapperCol?: AntdColProps;
  tooltipProps?: AntdTooltipProps;
}

export default function FormItem<FormValues, FormField>({
  name,
  render,
  renderBeforeInput,
  renderAfterInput,
  required,
  tooltipProps,
  normalize,
  displayItem,
  disabled,
  field,
  validateField,
  labelCol,
  wrapperCol,
  formValues,
  ...props
}: FormItemProps<FormValues, FormField>): React.ReactElement {
  const form = useBaseFormContext<FormValues>();
  const [isFieldTouched, setIsFieldTouched] = useState<boolean>(
    isValueDefined(form.getFieldValue(name)),
  );

  /**
   * This use effect overrides antd normalize because we want to normalize when item value is updated. Antd doesn't do that
   */
  useEffect(() => {
    if (normalize && formValues[name]) {
      setFieldValue(
        form,
        name,
        normalize(formValues[name], formValues[name], formValues),
      );
    }
  }, [formValues[name]]);

  useEffect(() => {
    //  is the field display an error and has not been touched yet (ie -> try to submit the form with the submit button)
    //  we set the field as touched
    if (form.getFieldError(name).length > 0 && !isFieldTouched) {
      //  set field as touched for custom isFieldTouched
      setIsFieldTouched(true);

      //  fix of an issue from antd
      //  detail :
      //  if you submit the form with some fields not touched yet (meaning you have not set this field value)
      //  antd will display the errors but validateTrigger of Form.Input is not updated
      //  fix :
      //  I force the form to refresh fields status, your input will
      //  validate when you change its value and not when you exit the field
      const values = formValues as Record<string, unknown>;
      const fields = Object.keys(values).map(key => {
        return {
          touched: true,
          validating: false,
          errors: form.getFieldError(key),
          name: key,
          value: values[key],
        };
      });
      form.setFields(fields);
    }
  }, [form, name, isFieldTouched]);

  //  this is used to override Antd Field default validation behavior
  if (isFieldTouched) {
    //  if the field is touched we want to validate the value on every change
    //  we don't display the feedback because of user's understanding issues.
    if (props.hasFeedback === undefined) {
      props.hasFeedback = false;
    }

    //  this prop configures when the form tries to validate the field
    //  set to onChange to validate the field everytime it changes
    if (props.validateTrigger === undefined) {
      props.validateTrigger = 'onChange';
    }
  } else {
    //  if the field is not touched
    //  when we are typing a field for the first time we don't try to validate the value everytime the value changes but rather when we exit the field

    //  we don't display the feedback since we are not validating the field
    if (props.hasFeedback === undefined) {
      props.hasFeedback = false;
    }

    //  this prop configures when the form tries to validate the field
    //  set to onBlur to validate the field only when we exit the field
    if (props.validateTrigger === undefined) {
      props.validateTrigger = 'onBlur';
    }
  }

  const children = render ? (
    render({
      name: name,
      disabled:
        typeof disabled === 'function' ? disabled(formValues) : disabled,
      form: form,
    })
  ) : (
    <BaseInput />
  );

  const validator =
    field && validateField ? () => validateField(field, formValues) : undefined;

  //  inject custom onBlur function to trigger touched field
  const ControlledInput = React.cloneElement(children, {
    onChange: (value: unknown) => {
      const { props }: { props?: React.DOMAttributes<string> } = children;
      props?.onChange?.bind(value);
    },
    onBlur: () => {
      setIsFieldTouched(true);
    },
  });

  return (
    <HideableElement visible={displayItem ? displayItem(formValues) : true}>
      <EncircledElement
        before={renderBeforeInput && renderBeforeInput(formValues)}
        after={renderAfterInput && renderAfterInput(formValues)}
      >
        <TooltipedElement props={tooltipProps}>
          <AntdForm.Item
            {...props}
            name={name}
            rules={[
              {
                required:
                  typeof required === 'function'
                    ? required(formValues)
                    : required,
                validator: () => {
                  setIsFieldTouched(true);
                  if (validator) {
                    return validator();
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
            labelCol={labelCol ? labelCol : { span: CSSUtils.defaultLabelCol }}
            wrapperCol={
              wrapperCol ? wrapperCol : { span: CSSUtils.defaultWrapperCol }
            }
          >
            {ControlledInput}
          </AntdForm.Item>
        </TooltipedElement>
      </EncircledElement>
    </HideableElement>
  );
}

interface HideableElementProps {
  children: React.ReactElement;
  visible: boolean;
}

function HideableElement({
  visible,
  children,
}: HideableElementProps): React.ReactElement {
  return visible ? children : <></>;
}

interface EncircledElementProps {
  children: React.ReactElement;
  before?: React.ReactElement;
  after?: React.ReactElement;
}

function EncircledElement({
  children,
  before,
  after,
}: EncircledElementProps): React.ReactElement {
  return (
    <>
      {before}
      {children}
      {after}
    </>
  );
}

interface TooltipedElementProps {
  props?: AntdTooltipProps;
  children: React.ReactElement;
}

function TooltipedElement({
  props,
  children,
}: TooltipedElementProps): React.ReactElement {
  return props ? <AntdTooltip {...props}>{children}</AntdTooltip> : children;
}
