import React, { useCallback, useState } from 'react';

import { Checkbox as AntdCheckbox } from 'antd';

import {
  ControleDonneesVehiculeRequestDto,
  ControleDonneesVehiculeViolationDtoFormFieldEnum,
  DonneesAControlerResponseDto,
  DonneesFd,
  DonneesSiv,
  DossierModificationViolationsDto,
  DossierResponseDto,
  ForceOrdreControllerApi,
  MarqueDto,
  ReferentielControllerApi,
} from 'lib_api/lib/api/gen';

import {
  LABEL_MARQUE_NON_REFERENCEE,
  LABEL_NON_RENSEIGNE,
  VALUE_NON_REFERENCE,
} from 'utils/global';
import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { useValidateField } from 'hooks/utils/handleValidationRequest';
import { useApi } from 'hooks/ApiStoreContext';
import { useAsyncSearchMarque } from 'hooks/search/async/useAsyncSearchMarque';
import { BaseOptionType } from 'components/BaseForm/Select/BaseSelect';
import AsyncSelect from 'components/BaseForm/Select/AsyncSelect';
import BaseInput from 'components/BaseForm/BaseInput';
import { FormInputProps } from 'components/BaseForm/types';
import BaseSelectWithSearch from 'components/BaseForm/Select/BaseSelectWithSearch';
import { fetchGenresimplifie } from 'search/searchGenreSimplifie';

import SelectModeleByIdCorrelationMarque from './ControlerDonneesVehiculeForm/Fields/SelectModeleByIdCorrelationMarque';

export interface ControleDonneesVehiculeFormValues {
  plusieursPlaques: boolean | null;
  newGenre: string | null;
  newMarque: BaseOptionType<MarqueDto>;
  newMarqueNonReferencee: boolean | null;
  newModele: string | null;
  newModeleNonReference: boolean | null;
  newPaysEtranger: boolean | null;
  newPlaqueImmatriculation: string | null;
  newPlaqueImmatriculationVisible: string | null;
  newSansPlaque: boolean | null;
  newVin: string | null;
}

export function buildInitialValues(
  donneesAControler: DonneesAControlerResponseDto | null,
): ControleDonneesVehiculeFormValues {
  return {
    plusieursPlaques:
      donneesAControler?.donneesFd?.plaqueImmatriculationVisible !== null,
    newMarque: {
      option: donneesAControler?.donneesFd?.marque || undefined,
      label:
        donneesAControler?.donneesFd?.marque?.libelle ||
        LABEL_MARQUE_NON_REFERENCEE,
    },
    newMarqueNonReferencee:
      donneesAControler?.donneesFd?.marqueNonReferencee || false,
    newModele:
      donneesAControler?.donneesFd?.modele?.idCorrelation ||
      VALUE_NON_REFERENCE,
    newModeleNonReference:
      donneesAControler?.donneesFd?.modeleNonReference || false,
    newGenre:
      donneesAControler?.donneesFd?.genreSimplifie?.idCorrelation || null,
    newPaysEtranger: donneesAControler?.donneesFd?.paysEtranger || false,
    newSansPlaque: donneesAControler?.donneesFd?.sansPlaque || false,
    newPlaqueImmatriculation:
      donneesAControler?.donneesFd?.plaqueImmatriculation || null,
    newPlaqueImmatriculationVisible:
      donneesAControler?.donneesFd?.plaqueImmatriculationVisible || null,
    newVin: donneesAControler?.donneesFd?.vin || null,
  };
}

export function buildRequestValues(
  values: ControleDonneesVehiculeFormValues,
): ControleDonneesVehiculeRequestDto {
  return {
    headers: null,
    newMarque:
      values.newMarque?.value === VALUE_NON_REFERENCE
        ? null
        : values.newMarque?.option?.idCorrelation || null,
    newMarqueNonReferencee: values.newMarque?.value === VALUE_NON_REFERENCE,
    newModele:
      values.newModele === VALUE_NON_REFERENCE
        ? null
        : values.newModele || null,
    newModeleNonReference: values.newModele === VALUE_NON_REFERENCE,
    newGenre: values.newGenre,
    newPaysEtranger: values.newPaysEtranger,
    newSansPlaque: values.newSansPlaque,
    newPlaqueImmatriculation: values.newPlaqueImmatriculation,
    newPlaqueImmatriculationVisible: values.newPlaqueImmatriculationVisible,
    newVin: values.newVin,
  };
}

export function useValidateControleDonneesVehiculeField(
  dossierId: string,
): (
  field: ControleDonneesVehiculeViolationDtoFormFieldEnum,
  formValues: ControleDonneesVehiculeFormValues,
) => Promise<void> {
  const { ForceOrdreControllerApi: foController } = useApi();

  const sendRequest = useCallback(
    (
      field: ControleDonneesVehiculeViolationDtoFormFieldEnum,
      request: ControleDonneesVehiculeRequestDto,
    ) => {
      return foController.validateFieldControleDonneesVehiculeUsingPOST(
        field,
        dossierId,
        request,
      );
    },
    [dossierId, foController],
  );

  const extractor = useCallback(
    (response: DossierModificationViolationsDto) => {
      return response.controleDonneesVehiculeViolationDtoList ?? [];
    },
    [],
  );

  return useValidateField(buildRequestValues, sendRequest, extractor).validate;
}

export function useSubmitControleDonneesVehicule(
  controller: ForceOrdreControllerApi,
  dossierId: string,
  setDossier: (dossier: DossierResponseDto) => void,
  closeModal: () => void,
): [(values: ControleDonneesVehiculeFormValues) => void, boolean] {
  const [inProgress, setInProgress] = useState<boolean>(false);
  return [
    (values: ControleDonneesVehiculeFormValues) => {
      setInProgress(true);
      return controller
        .updateControleDonneesVehiculeUsingPOST(
          dossierId,
          buildRequestValues(values),
        )
        .then(setDossier)
        .then(closeModal)
        .catch((error: Response) => {
          void backAlertMessage(error);
        })
        .finally(() => {
          setInProgress(false);
        });
    },
    inProgress,
  ];
}

export function buildInputs(
  refController: ReferentielControllerApi,
  donneesAControler: {
    donneesFd: DonneesFd | null | undefined;
    donneesSiv: DonneesSiv | null | undefined;
  } | null,
): FormInputProps<
  ControleDonneesVehiculeFormValues,
  ControleDonneesVehiculeViolationDtoFormFieldEnum
>[] {
  return [
    {
      name: 'plusieursPlaques',
      label: "Renseigner plusieurs plaques d'immatriculation",
      valuePropName: 'checked',
      render: () => {
        return <AntdCheckbox />;
      },
    },
    {
      field: ControleDonneesVehiculeViolationDtoFormFieldEnum.NEW_SANS_PLAQUE,
      name: 'newSansPlaque',
      label: 'Sans plaque',
      dependencies: ['newPlaqueImmatriculation'],
      valuePropName: 'checked',
      render: () => {
        return <AntdCheckbox />;
      },
    },
    {
      field: ControleDonneesVehiculeViolationDtoFormFieldEnum.NEW_PAYS_ETRANGER,
      name: 'newPaysEtranger',
      label: 'Pays étranger',
      dependencies: ['newSansPlaque', 'newPlaqueImmatriculation'],
      valuePropName: 'checked',
      render: () => {
        return <AntdCheckbox />;
      },
    },
    {
      field: ControleDonneesVehiculeViolationDtoFormFieldEnum.NEW_GENRE,
      required: true,
      name: 'newGenre',
      label: 'Genre',
      dependencies: ['newSansPlaque', 'newPlaqueImmatriculation'],
      render: () => {
        return (
          <AsyncSelect
            fetchOptions={() => {
              return fetchGenresimplifie(refController);
            }}
            getOptionValue={genre => genre.idCorrelation}
            getOptionLabel={genre => genre.libelle}
            showSearch={true}
          />
        );
      },
    },
    {
      field:
        ControleDonneesVehiculeViolationDtoFormFieldEnum.NEW_PLAQUE_VISIBLE,
      name: 'newPlaqueImmatriculationVisible',
      label: "Faux numéro d'identification ou d'immatriculation (visible)",
      dependencies: ['plusieursPlaques'],
      required: true,
      displayItem: (values: ControleDonneesVehiculeFormValues) => {
        return values.plusieursPlaques === true;
      },
      normalize: (value: string) => value.toUpperCase(),
    },
    {
      field: ControleDonneesVehiculeViolationDtoFormFieldEnum.NEW_PLAQUE,
      name: 'newPlaqueImmatriculation',
      label: "Numéro d'identification ou d'immatriculation",
      dependencies: ['newSansPlaque', 'newPaysEtranger', 'newGenre'],
      normalize: (value: string) => value.toUpperCase(),
      required: (values: ControleDonneesVehiculeFormValues) => {
        return values.newSansPlaque !== true;
      },
      disabled: (values: ControleDonneesVehiculeFormValues) => {
        return values.newSansPlaque === true;
      },
      extra: (
        <div className="helpText">
          SIV :&nbsp;
          {donneesAControler?.donneesSiv?.plaqueImmatriculation ||
            LABEL_NON_RENSEIGNE}
        </div>
      ),
      render: ({ disabled }) => {
        return <BaseInput disabled={disabled} />;
      },
    },
    {
      field: ControleDonneesVehiculeViolationDtoFormFieldEnum.NEW_VIN,
      name: 'newVin',
      label: 'Numéro VIN',
      extra: (
        <div className="helpText">
          SIV : {donneesAControler?.donneesSiv?.vin || LABEL_NON_RENSEIGNE}
        </div>
      ),
    },
    {
      field: ControleDonneesVehiculeViolationDtoFormFieldEnum.NEW_MARQUE,
      required: true,
      name: 'newMarque',
      label: 'Marque',
      render: () => {
        return (
          <BaseSelectWithSearch
            useSearchEntities={useAsyncSearchMarque(['EXACTE', 'INCONNUE'])}
            getOptionValue={(marque: MarqueDto | null) =>
              marque ? marque.idCorrelation || '' : VALUE_NON_REFERENCE
            }
            getOptionLabel={(marque: MarqueDto | null) =>
              marque ? marque.libelle : LABEL_MARQUE_NON_REFERENCEE
            }
            additionalOptions={[null]}
          />
        );
      },
      extra: (
        <div className="helpText">
          SIV :&nbsp;
          {donneesAControler?.donneesSiv?.marque?.libelle ||
            LABEL_NON_RENSEIGNE}
        </div>
      ),
    },
    {
      label: 'Modèle',
      name: 'newModele',
      field: ControleDonneesVehiculeViolationDtoFormFieldEnum.NEW_MODELE,
      required: true,
      render: () => {
        return <SelectModeleByIdCorrelationMarque idMarqueField="newMarque" />;
      },
      extra: (
        <div className="helpText">
          SIV :&nbsp;
          {donneesAControler?.donneesSiv?.modele?.libelle ||
            LABEL_NON_RENSEIGNE}
        </div>
      ),
    },
  ];
}
