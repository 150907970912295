import React from 'react';

import {
  ControleDonneesVehiculeViolationDtoFormFieldEnum,
  DonneesAControlerResponseDto,
} from 'lib_api/lib/api/gen';

import { useApi } from 'hooks/ApiStoreContext';
import BaseForm from 'components/BaseForm/BaseForm';
import { FormInputProps, WarningMessage } from 'components/BaseForm/types';
import {
  buildInitialValues,
  buildInputs,
  ControleDonneesVehiculeFormValues,
  useValidateControleDonneesVehiculeField,
} from '../utils';
import { ControlerDonneesVehiculeFieldName } from './types';
import './ControlerDonnesVehiculeForm.less';

interface ControlerDonneesVehiculeFormProps {
  onCancel: () => void;
  cancelButtonLabel?: string;
  donneesAControler: DonneesAControlerResponseDto | null;
  dossierId: string;
  submit: (values: ControleDonneesVehiculeFormValues) => void;
  submitInProgress: boolean;
  warningMessage: WarningMessage;
  ignoredFieldsNames?: ControlerDonneesVehiculeFieldName[];
}

function filterInputs(
  inputs: FormInputProps<
    ControleDonneesVehiculeFormValues,
    ControleDonneesVehiculeViolationDtoFormFieldEnum
  >[],
  ignoredFieldsNames: ControlerDonneesVehiculeFieldName[] | undefined,
) {
  return inputs.filter(
    input =>
      !ignoredFieldsNames?.includes(
        input.name as ControlerDonneesVehiculeFieldName,
      ),
  );
}

const ControlerDonneesVehiculeForm: React.FC<
  ControlerDonneesVehiculeFormProps
> = ({
  dossierId,
  onCancel,
  cancelButtonLabel,
  donneesAControler,
  submit,
  submitInProgress,
  warningMessage,
  ignoredFieldsNames,
}: ControlerDonneesVehiculeFormProps) => {
  const {
    ReferentielControllerApi: refController,
    ForceOrdreControllerApi: foController,
  } = useApi();

  const validateField = useValidateControleDonneesVehiculeField(dossierId);

  const inputs = buildInputs(refController, donneesAControler);

  const filteredInputs: FormInputProps<
    ControleDonneesVehiculeFormValues,
    ControleDonneesVehiculeViolationDtoFormFieldEnum
  >[] = filterInputs(inputs, ignoredFieldsNames);

  return (
    <BaseForm
      initialValues={buildInitialValues(donneesAControler)}
      validateField={validateField}
      onChange={(changed, all) => {
        return {
          ...all,
          newModele: changed.newMarque ? null : all.newModele,
          newPlaqueImmatriculation:
            all.newSansPlaque === true ? null : all.newPlaqueImmatriculation,
        };
      }}
      inputs={filteredInputs}
      warningMessage={warningMessage}
      renderButtonAction={(Submit, Cancel) => {
        return (
          <div className="controlerDonnesVehiculeFormBtns">
            <div className="btnWrapper">{Cancel}</div>
            <div className="btnWrapper">{Submit}</div>
          </div>
        );
      }}
      onSubmit={submit}
      onCancel={onCancel}
      cancelButtonLabel={cancelButtonLabel}
      isSubmitting={submitInProgress}
    />
  );
};

export default ControlerDonneesVehiculeForm;
