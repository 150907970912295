import { createStore } from './utils/createStore';

export interface Environnement {
  BACKEND_URL: string;
  CAMUNDA_URL: string;
  CAMUNDA_COCKPIT_PATH: string;
  MAINTENANCE_START_DATE?: string;
  MAINTENANCE_END_DATE?: string;
  USER_MANUAL_FOLDER?: string; // path to the folder containing user manuals
  GF_USER_MANUAL?: string; // GF user manual name
  AF_USER_MANUAL?: string; // AF user manual name
  FO_USER_MANUAL?: string; // FO user manual name
}

export const store = createStore<Environnement>();

export const EnvironnementProvider = store.Provider;
export const useEnvironnement = store.useStore;
