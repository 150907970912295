import React, { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { UserDtoProfileEnum } from 'lib_api/lib/api/gen';

import NavBar from 'components/NavBar';
import { NOT_FOUND_PATH, profileEnumMapping } from 'utils/enumData';
import SortProvider from 'hooks/SortStoreContext';
import {
  TablePagination,
  TableSort,
} from 'components/WrappedComponents/Table/types';
import PaginationProvider from 'hooks/PaginationStoreContext';
import { SortOptions } from 'hooks/dossiers/useSearchDossierSummaries';
import AdminFonctionnelFilterProvider from 'hooks/AdminFonctionnelFilterStoreContext';
import { useActionsRequises } from 'hooks/useActionsRequises';
import { PAGINATION_DEFAULT } from 'utils/DashboardUtils';
import { useAdminNavbarContent } from './adminNavbarContent';
import useActiveHermesSolution from './useActiveHermesSolution';
import Dashboard from './Dashboard';
import ReferentielImport from './Referentiels/Import';
import ReferentielExport from './Referentiels/Export';
import {
  DashboardAutoriteFourriere,
  DashboardUniteFo,
} from './Referentiels/Dashboards';
import DashboardFourriere from './Referentiels/Dashboards/Fourriere/DashboardFourriere';
import InformationMessage from './Referentiels/InformationMessage';
import Manuels from './Referentiels/Manuels';
import FicheDescriptive from './Referentiels/FicheDescriptive';
import RoutesDomaine from './Domaine';
import Comptes from './Comptes/RoutesComptes';
import Classement from './Classement';
import PageDossier from './PageDossier';
import DashboardMarque from './Referentiels/Dashboards/Marque/DashboardMarque';
import DashboardCentreVHU from './Referentiels/Dashboards/CentreVHU/DashboardCentreVHU';
import DashboardFaqDomaine from './Referentiels/Dashboards/FaqDomaine/DashboardFaqDomaine';
import DashboardFaq from './Referentiels/Dashboards/Faq/DashboardFaq';
import DashboardMotifMef from './Referentiels/Dashboards/MotifMef/DashboardMotifMef';
import FAQAdmin from '../../components/FAQ/FAQAdmin';
import DashboardVenteDossiers from './Flux/VenteDossiers/DashboardVenteDossiers';
import DashboardWarnings from './Flux/VenteDossiers/Details/DashboardWarnings/DashboardWarnings';
import { DashboardAdminFonctionnelFilter } from './Dashboard/types';
import DashboardMotifSortie from './Referentiels/Dashboards/MotifSortie/DashboardMotifSortie';
import DashboardTypeAf from './Referentiels/Dashboards/TypeAf/DashboardTypeAf';

const AdminRouter: React.FC = () => {
  const [pagination, setPagination] =
    useState<TablePagination>(PAGINATION_DEFAULT);
  const [filter, setFilter] = useState<
    Partial<DashboardAdminFonctionnelFilter>
  >({});
  const [sort, setSort] = useState<Partial<TableSort<SortOptions>>>({
    IMMATRICULATION: true,
  });
  const adminProfileData =
    profileEnumMapping[UserDtoProfileEnum.ADMIN_FONCTIONNEL];
  const activeSolution = useActiveHermesSolution();
  const navbarContent = useAdminNavbarContent(
    adminProfileData.homePath,
    activeSolution,
  );

  const adminActionsRequises = useActionsRequises();

  return (
    <PaginationProvider
      value={{ pagination: pagination, setPagination: setPagination }}
    >
      <AdminFonctionnelFilterProvider
        value={{
          filter: filter,
          setFilter: setFilter,
          actionsRequises: adminActionsRequises,
        }}
      >
        <SortProvider value={{ sort: sort, setSort: setSort }}>
          <NavBar items={navbarContent} />
          <Routes>
            <Route path="" element={<Dashboard />} />
            <Route path="referentiels">
              <Route path="import" element={<ReferentielImport />} />
              <Route path="export" element={<ReferentielExport />} />
              <Route path="manuels" element={<Manuels />} />
              <Route path="fiche-descriptive" element={<FicheDescriptive />} />
              <Route
                path="information-message"
                element={<InformationMessage />}
              />
              <Route path="dashboard">
                <Route path="unite-fo" element={<DashboardUniteFo />} />
                <Route path="fourriere" element={<DashboardFourriere />} />
                <Route
                  path="autorite-fourriere"
                  element={<DashboardAutoriteFourriere />}
                />
                <Route
                  path="type-autorite-fourriere"
                  element={<DashboardTypeAf />}
                />
                <Route path="marque" element={<DashboardMarque />} />
                <Route path="motif-mef" element={<DashboardMotifMef />} />
                <Route path="motif-sortie" element={<DashboardMotifSortie />} />
                <Route path="centre-vhu" element={<DashboardCentreVHU />} />
                <Route path="faq-domaine" element={<DashboardFaqDomaine />} />
                <Route path="faq">
                  <Route path="" element={<DashboardFaq />} />
                  <Route path="view/:profile" element={<FAQAdmin />} />
                </Route>
              </Route>
            </Route>
            <Route path="domaine/*" element={<RoutesDomaine />} />
            <Route path="comptes" element={<Comptes />} />
            <Route path="classement" element={<Classement />} />
            <Route path="fluxs">
              <Route
                path="vente-dossiers"
                element={<DashboardVenteDossiers />}
              />
              <Route
                path="vente-dossiers/:id"
                element={<DashboardWarnings />}
              />
            </Route>
            <Route path="dossier/:id" element={<PageDossier />} />
            <Route
              path="*"
              element={<Navigate replace to={NOT_FOUND_PATH} />}
            />
          </Routes>
        </SortProvider>
      </AdminFonctionnelFilterProvider>
    </PaginationProvider>
  );
};

export default AdminRouter;
