import { createContext, useContext, Dispatch, SetStateAction } from 'react';

import { ActionsRequisesDtoActionsRequisesEnum } from 'lib_api/lib/api/gen';
import { DashboardAdminFonctionnelFilter } from '../pages/admin-fonctionnel/Dashboard/types';

interface AdminFonctionnelFilterContextContent {
  filter: Partial<DashboardAdminFonctionnelFilter>;
  setFilter: Dispatch<SetStateAction<Partial<DashboardAdminFonctionnelFilter>>>;
  // all actions requises configured for admin users
  actionsRequises: ActionsRequisesDtoActionsRequisesEnum[] | undefined;
}

const AdminFonctionnelFilterContext =
  createContext<AdminFonctionnelFilterContextContent | null>(null);

export const useAdminFonctionnelFilterContext = (): [
  Partial<DashboardAdminFonctionnelFilter>,
  Dispatch<SetStateAction<Partial<DashboardAdminFonctionnelFilter>>>,
  ActionsRequisesDtoActionsRequisesEnum[] | undefined,
] => {
  const context = useContext(AdminFonctionnelFilterContext);
  if (context === null) {
    throw new Error('useFilterContext called outside Context.');
  }

  return [context.filter, context.setFilter, context.actionsRequises];
};

const AdminFonctionnelFilterProvider = AdminFonctionnelFilterContext.Provider;

export default AdminFonctionnelFilterProvider;
