import React from 'react';
import { Checkbox as AntdCheckbox, Typography as AntdTypo } from 'antd';

import { TypeAutoriteFourriereDto } from 'lib_api/lib/api/gen';

import { useApi } from 'hooks/ApiStoreContext';
import { fetchTypeAutoriteFourriere } from 'search/searchTypeAutoriteFourriere';
import BaseForm from 'components/BaseForm/BaseForm';
import AutocompleteDepartement from 'components/BaseForm/Autocomplete/AutocompleteDepartement';
import AsyncSelect from 'components/BaseForm/Select/AsyncSelect';
import BaseInput from 'components/BaseForm/BaseInput';
import { getValueFromEventContainingOnlyNumeric } from 'components/BaseForm/utils';

import { FilterDashboardAutoriteFourriere } from 'types/referentiels/AutoriteFourriere';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';

import FilterFormFooter from './FilterFormFooter';

interface FilterFormProps {
  filter: FilterDashboardAutoriteFourriere;
  setFilter: (value: FilterDashboardAutoriteFourriere) => void;
}

function FilterFormAutoriteFourriere({
  filter,
  setFilter,
}: FilterFormProps): React.ReactElement {
  const { ReferentielControllerApi: refController } = useApi();
  return (
    <>
      <AntdTypo.Title level={3}>Filtres</AntdTypo.Title>
      <BaseForm
        initialValues={filter}
        onSubmit={values => {
          setFilter(values);
        }}
        inputs={[
          {
            name: 'idCorrelation',
            label: 'Id de corrélation',
          },
          {
            name: 'codeDepartement',
            label: 'Code département',
            render: () => {
              return <BaseInput />;
            },
          },
          {
            name: 'libelleDepartement',
            label: 'Libellé département',
            render: props => {
              return (
                <AutocompleteDepartement
                  {...props}
                  codeField="codeDepartement"
                />
              );
            },
          },
          {
            name: 'codePostal',
            label: 'Code postal',
            getValueFromEvent: getValueFromEventContainingOnlyNumeric,
            render: () => {
              return <BaseInput placeholder={FormPlaceholders.Number} />;
            },
          },
          {
            name: 'ville',
            label: 'Ville',
          },
          {
            name: 'idCorrelationTypeAutorite',
            label: "Type d'autorité de fourrière",
            render: () => {
              const fetchOptions = () => {
                return fetchTypeAutoriteFourriere(refController);
              };
              const getValue = (type: TypeAutoriteFourriereDto) => {
                return type.idCorrelation;
              };
              const getLabel = (type: TypeAutoriteFourriereDto) => {
                return type.type;
              };

              return (
                <AsyncSelect
                  fetchOptions={fetchOptions}
                  getOptionValue={getValue}
                  getOptionLabel={getLabel}
                  allowClear
                />
              );
            },
          },
          {
            name: 'idHermes',
            label: 'Id Hermes',
          },
          {
            name: 'emptyIdHermes',
            label: 'Id Hermes manquant',
            valuePropName: 'checked',
            render: () => {
              return <AntdCheckbox />;
            },
          },
          {
            name: 'idHermesUtilisateur',
            label: 'Id utilisateur remettant',
          },
          {
            name: 'emptyIdHermesUtilisateur',
            label: 'Id utilisateur remettant manquant',
            valuePropName: 'checked',
            render: () => {
              return <AntdCheckbox />;
            },
          },
        ]}
        renderButtonAction={() => {
          return <FilterFormFooter setFilter={setFilter} />;
        }}
      />
    </>
  );
}

export default FilterFormAutoriteFourriere;
