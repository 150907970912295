import React, { useEffect } from 'react';
import { DossierResponseDto } from 'lib_api/lib/api/gen';
import { useDossierContext } from 'hooks/dossiers/DossierContext';
import { useFetchDonneesAControler } from 'hooks/dossiers/useFetchDonneesAControler';
import { extractNonnullDossierIdOrThrow } from 'utils/dossierUtils';
import { extractStatutAlienationFromDossierOrThrow } from 'utils/alienationUtils';
import { WarningMessage } from 'components/BaseForm/types';
import ControlerDonneesVehiculeForm from 'components/FicheDescriptiveDisplay/ControlerDonneesVehicule/ControlerDonneesVehiculeForm/ControlerDonneesVehiculeForm';
import AsyncComponent from 'components/AsyncComponent/AsyncComponent';
import { useControleBlocageVenteErreurIdentification } from './utils';

interface ControleErreurIdentificationVehiculeFormProps {
  previous: () => void;
  close: () => void;
}

const ControleErreurIdentificationVehiculeForm = ({
  previous,
  close,
}: ControleErreurIdentificationVehiculeFormProps): React.ReactElement => {
  const [dossier, setDossier] = useDossierContext();
  const dossierId = extractNonnullDossierIdOrThrow(dossier);
  const {
    object: donneesAControler,
    fetch,
    inProgress: fetchInProgress,
    errorOccured: fetchErrorOccured,
  } = useFetchDonneesAControler(dossierId);
  const statutAlienation = extractStatutAlienationFromDossierOrThrow(dossier);
  const [submit, submitInProgress] =
    useControleBlocageVenteErreurIdentification(
      dossierId,
      (updatedDossier: DossierResponseDto) => {
        setDossier(updatedDossier);
      },
      close,
      statutAlienation,
      donneesAControler?.donneesFd,
    );

  useEffect(fetch, [fetch]);

  const warningMessage: WarningMessage = {
    title: "Conséquence de l'enregistrement",
    detail:
      'Si une modification de l’immatriculation / identification ou du VIN a été appliquée, une nouvelle consultation du SIV sera réalisée pour que le SI Fourrières récupère les coordonnées du propriétaire effectif du véhicule.' +
      ' Autrement, le dossier sera remis aux Domaines pour une nouvelle tentative de vente.',
    style: { maxWidth: '100%' },
  };
  return (
    <>
      <h3>Vérifier les informations du véhicule</h3>
      <AsyncComponent
        render={() => {
          return (
            <ControlerDonneesVehiculeForm
              onCancel={previous}
              cancelButtonLabel="Retour"
              donneesAControler={donneesAControler}
              dossierId={dossierId}
              submit={submit}
              submitInProgress={submitInProgress}
              warningMessage={warningMessage}
              ignoredFieldsNames={['newMarque', 'newModele']}
            />
          );
        }}
        inProgress={fetchInProgress}
        reload={fetch}
      ></AsyncComponent>
    </>
  );
};

export default ControleErreurIdentificationVehiculeForm;
