import React, { ReactElement } from 'react';
import { Button as AntdButton } from 'antd';

import { useDossierAdminContext } from 'hooks/dossierAdmin/DossierAdminContext';
import { useRelanceVente } from './utils';

import './ControleBlocageVente.less';

interface ControleBlocageVenteProps {
  close: () => void;
}

export default function ControleBlocageVenteDoublon({
  close,
}: ControleBlocageVenteProps): ReactElement {
  const [{ result: dossier, setDossier }, { fetchEvents }] =
    useDossierAdminContext();
  const [submit, submitInProgress] = useRelanceVente(
    dossier,
    setDossier,
    fetchEvents,
    close,
  );
  return (
    <div className="controleBlocageVente">
      <h3>Contrôler le blocage de la vente</h3>
      <p>
        Le commissaire aux ventes des Domaines a identifié ce véhicule comme
        étant un doublon d’un dossier déjà existant et en cours de traitement.
      </p>
      <p>
        <b>Afin de débloquer la situation :</b>
      </p>
      <ul>
        <li>
          Si le dossier est effectivement un doublon, supprimer ledit dossier.
        </li>
        <li>
          Si le doublon n’est pas avéré, cliquer sur « Relancer la vente » afin
          de remettre le dossier aux Domaines.
        </li>
      </ul>

      <p className="consequenceActionRelance">
        <span>
          <b>Conséquence de l’action « Relancer la vente »</b>
        </span>
        <br />
        <span>
          Au clic sur « Relancer la vente », le dossier sera remis aux Domaines
          pour une nouvelle tentative de vente.
          <br></br>
          Le cas échéant, vous confirmez donc que le dossier en présence
          n&apos;est pas un doublon.
        </span>
      </p>
      <div className="buttonsContainer">
        <AntdButton onClick={close}>Annuler</AntdButton>
        <AntdButton type="primary" disabled={submitInProgress} onClick={submit}>
          Relancer la vente
        </AntdButton>
      </div>
    </div>
  );
}
