import {
  AutoriteFourriereDto,
  ReferentielControllerApi,
  ReferentielSearchResultDtoAutoriteFourriereDto,
} from 'lib_api/lib/api/gen';

import { FilterDashboardAutoriteFourriere } from 'types/referentiels/AutoriteFourriere';
import { AutoriteFourriereSortOptions } from 'enums/referentiels/AutoriteFourriere';
import {
  TablePagination,
  TableSort,
} from 'components/WrappedComponents/Table/types';

export function searchAutoriteFourriereUsingSpecs(
  controller: ReferentielControllerApi,
  filters: FilterDashboardAutoriteFourriere,
  sorts: TableSort<AutoriteFourriereSortOptions>,
  pagination: TablePagination,
): Promise<ReferentielSearchResultDtoAutoriteFourriereDto> {
  return controller.searchAutoriteFourriereUsingSpecsUsingGET({
    filters: {
      ...filters,
      page: pagination.page,
      pageSize: pagination.pageSize,
      codeDepartement: filters.codeDepartement,
      libelleDepartement: filters.libelleDepartement,
    },
    sorts: {
      codePostal: sorts?.CODE_POSTAL,
      departement: sorts?.DEPARTEMENT,
      libelle: sorts?.LIBELLE,
      type: sorts?.TYPE,
      ville: sorts?.VILLE,
    },
  });
}

export function fetchAutoriteFourriere(
  refController: ReferentielControllerApi,
): Promise<AutoriteFourriereDto[]> {
  return refController.getValidAutoriteFourriereUsingGET().then(response => {
    return response.referenceDtoList;
  });
}

export function fetchAutoriteFourriereOfFourriere(
  refController: ReferentielControllerApi,
  idCorrelationFourriere: string,
): Promise<AutoriteFourriereDto[]> {
  return refController
    .getValidAutoritesFourriereOfFourriereUsingGET(idCorrelationFourriere)
    .then(response => {
      return response.referenceDtoList;
    });
}

export function fetchValidAutoriteFourriere(
  refController: ReferentielControllerApi,
  idCorrelationAutoriteFourriere: string,
): Promise<AutoriteFourriereDto> {
  return refController
    .getValidAutoriteFourriereByIdCorrelationUsingGET(
      idCorrelationAutoriteFourriere,
    )
    .then(response => {
      return response;
    });
}
