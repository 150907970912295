import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  ComputedResponseDtoListAvailableFilesEnum,
  DossierDtoAdminFonctionnelActionsDisponiblesEnum,
} from 'lib_api/lib/api/gen';

import { useDownloadDossierAdminFonctionnel } from 'hooks/dossiers/useDownloadDossierAdminFonctionnel';
import { useDossierAdminContext } from 'hooks/dossierAdmin/DossierAdminContext';
import { useAdminFonctionnelFilterContext } from 'hooks/AdminFonctionnelFilterStoreContext';
import { useDisplayActionsRequisesBannerAdmin } from 'hooks/useActionsRequises';
import useFetchProcessInstances from 'hooks/dossierAdmin/useFetchProcessInstances';
import { useModal } from 'hooks/modal/useModal';
import ActionAndFileWrapperAdmin from 'components/ActionAndFileWrapper/ActionAndFileWrapperAdmin';
import AsyncComponent from 'components/AsyncComponent/AsyncComponent';
import { AvailableAction } from 'components/ActionAndFileWrapper/types';
import BaseModal from 'components/WrappedComponents/BaseModal/BaseModal';
import { ActionsRequisesLinkState } from 'utils/actionsRequisesUtils';
import ControleBlocageVente from '../ControleBlocageVente/ControleBlocageVente';
import { ModalConfirmationAnnulationSortie } from './ModalConfirmationAnnulationSortie';
import { ModalSuppressionDossier } from './ModalSuppressionDossierProps';
import UpdateEntiteRatachement from './UpdateEntitesRattachements/UpdateEntiteRatachement';
import UpdateSortie from './UpdateSortie/UpdateSortie';
import UpdateClassement from './UpdateClassement/UpdateClassement';
import UpdateDonneesVehicule from './UpdateDonneesVehicule/UpdateDonneesVehicule';
import FixDataNotificationManuelle from './FixDataNotificationManuelle/FixDataNotificationManuelle';
import ControleBlocageVenteDoublon from '../ControleBlocageVente/ContrôleBlocageVenteDoublon';

import './ActionsSuiviVehicule.less';

type ActionMapping = {
  [key in DossierDtoAdminFonctionnelActionsDisponiblesEnum]?: AvailableAction;
};

const getValueOrEmptyList = <T,>(value?: T): T[] => (value ? [value] : []);

const ActionsSuiviVehicule = (): React.ReactElement => {
  const { state } = useLocation() as { state: ActionsRequisesLinkState };
  const [, setIsDownloading] = useState<boolean>(false);
  const [{ result: dossierAdmin }] = useDossierAdminContext();
  const [, , adminActionsRequises] = useAdminFonctionnelFilterContext();
  const download = useDownloadDossierAdminFonctionnel(
    setIsDownloading,
    dossierAdmin.id,
    dossierAdmin.immatriculation,
  );
  const { processInstances, errorOccured, fetchInstances } =
    useFetchProcessInstances(dossierAdmin.id);

  useDisplayActionsRequisesBannerAdmin(dossierAdmin, adminActionsRequises);

  const updateEntiteRattachementModal = useModal();
  const cancelSortieModal = useModal();
  const deleteDossierModal = useModal();
  const updateDonneesVehicule = useModal();
  const updateSortieModal = useModal();
  const updateClassementModal = useModal();
  const fixDataNotificationManuelle = useModal();
  const controleBlocageVente = useModal(
    state?.openControleBlocageVenteImmoPJ ||
      state?.openControleBlocageVenteOpposition ||
      false,
  );
  const controleBlocageVenteDoublon = useModal(
    state?.openControleBlocageVenteDoublon || false,
  );

  const actionMapping: ActionMapping = useMemo(() => {
    return {
      EXPORT_DONNEES_DOSSIER: {
        title: 'Exporter les données du dossier',
        onClick: () => download(),
      },
      UPDATE_ENTITES_RATTACHEMENT: {
        title: 'Modifier les entités de rattachement',
        onClick: updateEntiteRattachementModal.open,
      },
      ANNULATION_SORTIE_VEHICULE: {
        title: 'Annuler la sortie',
        onClick: cancelSortieModal.open,
      },
      MODIFICATION_SORTIE_VEHICULE: {
        title: 'Modifier les données de sortie',
        onClick: updateSortieModal.open,
      },
      SUPPRESSION_DOSSIER: {
        title: 'Supprimer le dossier',
        onClick: deleteDossierModal.open,
      },
      UPDATE_CLASSEMENT: {
        title: 'Modifier le classement',
        onClick: updateClassementModal.open,
      },
      UPDATE_DONNEES_VEHICULE: {
        title: "Modifier les données du véhicule et de l'infraction",
        onClick: updateDonneesVehicule.open,
      },
      CORRIGER_DONNEES_NOTIFICATION_MANUELLE: {
        title: 'Modifier le suivi de notification',
        onClick: fixDataNotificationManuelle.open,
      },
      RELANCE_VENTE_IMMO_PJ: {
        title: 'Contrôler le blocage de la vente',
        onClick: controleBlocageVente.open,
      },
      RELANCE_VENTE_OPPOSITION: {
        title: 'Contrôler le blocage de la vente',
        onClick: controleBlocageVente.open,
      },
      RELANCE_VENTE_DOUBLON: {
        title: 'Contrôler le blocage de la vente',
        onClick: controleBlocageVenteDoublon.open,
      },
    };
  }, []);

  const actions: AvailableAction[] =
    dossierAdmin.actionsDisponibles?.flatMap(action =>
      getValueOrEmptyList(actionMapping[action]),
    ) ?? [];

  const availableFiles: ComputedResponseDtoListAvailableFilesEnum[] =
    dossierAdmin.listAvailableFiles?.map(
      value => ComputedResponseDtoListAvailableFilesEnum[value],
    ) ?? [];

  return (
    <>
      <AsyncComponent
        errorOccured={errorOccured}
        inProgress={false}
        reload={fetchInstances}
        render={() => {
          return (
            <ActionAndFileWrapperAdmin
              filesList={availableFiles}
              actions={actions}
              dossierId={dossierAdmin.id}
              processInstances={processInstances}
            />
          );
        }}
      />
      <BaseModal
        width={700}
        isVisible={updateEntiteRattachementModal.visible}
        closeModal={updateEntiteRattachementModal.close}
      >
        <UpdateEntiteRatachement
          closeModal={updateEntiteRattachementModal.close}
        />
      </BaseModal>
      <BaseModal
        width={700}
        isVisible={updateSortieModal.visible}
        closeModal={updateSortieModal.close}
      >
        <UpdateSortie closeModal={updateSortieModal.close} />
      </BaseModal>
      <BaseModal
        width={700}
        isVisible={updateDonneesVehicule.visible}
        closeModal={updateDonneesVehicule.close}
      >
        <UpdateDonneesVehicule closeModal={updateDonneesVehicule.close} />
      </BaseModal>
      <BaseModal
        width={700}
        isVisible={updateClassementModal.visible}
        closeModal={updateClassementModal.close}
      >
        <UpdateClassement closeModal={updateClassementModal.close} />
      </BaseModal>
      <BaseModal
        width={700}
        isVisible={fixDataNotificationManuelle.visible}
        closeModal={fixDataNotificationManuelle.close}
      >
        <FixDataNotificationManuelle
          closeModal={fixDataNotificationManuelle.close}
        />
      </BaseModal>
      <ModalSuppressionDossier
        visible={deleteDossierModal.visible}
        close={deleteDossierModal.close}
        dossierId={dossierAdmin.id}
      />
      <ModalConfirmationAnnulationSortie
        visible={cancelSortieModal.visible}
        close={cancelSortieModal.close}
        dossierId={dossierAdmin.id}
      />
      <BaseModal
        width={750}
        isVisible={controleBlocageVente.visible}
        closeModal={controleBlocageVente.close}
      >
        <ControleBlocageVente close={controleBlocageVente.close} />
      </BaseModal>
      <BaseModal
        width={750}
        isVisible={controleBlocageVenteDoublon.visible}
        closeModal={controleBlocageVenteDoublon.close}
      >
        <ControleBlocageVenteDoublon
          close={controleBlocageVenteDoublon.close}
        />
      </BaseModal>
    </>
  );
};

export default ActionsSuiviVehicule;
