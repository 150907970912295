import React from 'react';
import { Empty as AntdEmpty, Spin as AntdSpin } from 'antd';

import { MarqueDto } from 'lib_api/lib/api/gen';

import BaseSelectMultiList from 'components/BaseForm/Select/BaseSelectMultiList';
import { CommonBaseSelectProps } from 'components/BaseForm/Select/CommonBaseSelect';
import { setFieldValue } from 'components/BaseForm/utils';
import { useBaseFormContext } from 'components/BaseForm/BaseFormContext';

interface SelectMarqueMultiListProps<FormValues>
  extends CommonBaseSelectProps<FormValues, MarqueDto | null> {
  marques: MarqueDto[];
  inProgress: boolean;
  paginate: () => void;
  marquesPrio: MarqueDto[];
}

export default function BaseSelectMarqueMultiList<FormValues>({
  marques,
  inProgress,
  paginate,
  marquesPrio,
  ...props
}: SelectMarqueMultiListProps<FormValues>): React.ReactElement {
  const form = useBaseFormContext<FormValues>();
  return (
    <BaseSelectMultiList
      {...props}
      options={[
        {
          label: 'Marques fréquentes',
          options: marquesPrio,
        },
        {
          label: 'Autres',
          options: marques,
        },
      ]}
      notFoundContent={inProgress ? <AntdSpin size="small" /> : <AntdEmpty />}
      getOptionLabel={(marque: MarqueDto) => marque.libelle}
      getOptionValue={(marque: MarqueDto) => marque.id}
      showSearch
      onBottomReached={paginate}
      onChange={(value, option) => {
        props.onChange && props.onChange(value, option);
        if (option && props.id) {
          setFieldValue(
            form,
            props.id,
            Array.isArray(option) ? null : option.value,
          );
        }
      }}
    />
  );
}
