import React, { useEffect } from 'react';
import { Modal as AntdModal } from 'antd';
import { DossierResponseDto } from 'lib_api/lib/api/gen';

import ComparaisonSivFd from './ComparaisonSivFd/ComparaisonSivFd';
import ControlerDonneesVehiculeForm from './ControlerDonneesVehiculeForm/ControlerDonneesVehiculeForm';
import { useBoolean } from 'utils/genericUtils';
import { extractNonnullDossierIdOrThrow } from 'utils/dossierUtils';
import { useApi } from 'hooks/ApiStoreContext';
import { useDossierContext } from 'hooks/dossiers/DossierContext';
import { useFetchDonneesAControler } from 'hooks/dossiers/useFetchDonneesAControler';
import { useSubmitControleDonneesVehicule } from './utils';
import AsyncComponent from '../../AsyncComponent/AsyncComponent';

import './ModalControlerDonneesVehicule.less';

interface ModalControlerDonneesVehiculeProps {
  visible?: boolean | undefined;
  close: () => void;
}

const ModalControlerDonneesVehicule = ({
  visible,
  close,
}: ModalControlerDonneesVehiculeProps): React.ReactElement => {
  const {
    value: formIsOpen,
    setIsTrue: openForm,
    setIsFalse: closeForm,
  } = useBoolean();

  const foController = useApi().ForceOrdreControllerApi;

  const [dossier, setDossier] = useDossierContext();
  const dossierId = extractNonnullDossierIdOrThrow(dossier);
  const {
    object: donneesAControler,
    fetch,
    inProgress: fetchInProgress,
    errorOccured: fetchErrorOccured,
  } = useFetchDonneesAControler(dossierId);

  const [submit, submitInProgress] = useSubmitControleDonneesVehicule(
    foController,
    dossierId,
    (updatedDossier: DossierResponseDto) => {
      setDossier(updatedDossier);
      return fetch();
    },
    close,
  );

  const warningMessage = {
    title: "Conséquence de l'enregistrement",
    detail:
      'Si des modifications ont été effectuées, la fiche descriptive sera mise à jour avec ces nouvelles données. Dans le cas d’une modification de l’immatriculation / identification ou du VIN, une nouvelle consultation du SIV sera réalisée pour que le SI Fourrières récupère les données effectives du véhicule (données techniques et coordonnées du propriétaire).',
    style: { maxWidth: '100%' },
  };

  useEffect(fetch, [fetch]);
  return (
    <AntdModal
      visible={visible}
      onCancel={() => {
        closeForm();
        close();
      }}
      title="Contrôler les données du véhicule"
      closable={true}
      maskClosable={true}
      className="modalControleDonneesVehicule"
      footer={null}
      width={812}
    >
      <AsyncComponent
        reload={fetch}
        inProgress={fetchInProgress}
        render={() => {
          return !formIsOpen ? (
            <ComparaisonSivFd
              onClose={close}
              openUpdateForm={openForm}
              donneesAControler={donneesAControler}
              submitInProgress={submitInProgress}
              submit={submit}
            />
          ) : (
            <ControlerDonneesVehiculeForm
              onCancel={closeForm}
              donneesAControler={donneesAControler}
              submitInProgress={submitInProgress}
              submit={submit}
              dossierId={dossierId}
              warningMessage={warningMessage}
            />
          );
        }}
      />
    </AntdModal>
  );
};

export default ModalControlerDonneesVehicule;
