import React from 'react';

import { Button as AntdButton } from 'antd';

import { ProcessInstanceUrl } from 'hooks/dossierAdmin/useFetchProcessInstances';
import AvailableProcessInstancesDropdown from './AvailableProcessInstancesDropdown/AvailableProcessInstancesDropdown';

interface AvailableProcessInstancesProps {
  processInstances: ProcessInstanceUrl[];
}

const AvailableProcessInstances: React.FC<AvailableProcessInstancesProps> = ({
  processInstances,
}: AvailableProcessInstancesProps): React.ReactElement => {
  const openCamundaWindow = (processInstance: ProcessInstanceUrl) => {
    window.open(processInstance.url, '_blank');
  };

  if (processInstances.length === 1) {
    return (
      <AntdButton
        onClick={() => openCamundaWindow(processInstances[0])}
        type="default"
        shape="round"
        className="ActionsButton"
      >
        Voir l&apos;instance Camunda
      </AntdButton>
    );
  }
  return (
    <AvailableProcessInstancesDropdown processInstances={processInstances} />
  );
};

export default AvailableProcessInstances;
