import React, { ReactElement } from 'react';
import { Button as AntdButton } from 'antd';

import { useDossierAdminContext } from 'hooks/dossierAdmin/DossierAdminContext';
import { useRelanceVente } from './utils';

import './ControleBlocageVente.less';

interface ControleBlocageVenteProps {
  close: () => void;
}

const ControleBlocageVente = ({
  close,
}: ControleBlocageVenteProps): ReactElement => {
  const [{ result: dossier, setDossier }, { fetchEvents }] =
    useDossierAdminContext();
  const [submit, submitInProgress] = useRelanceVente(
    dossier,
    setDossier,
    fetchEvents,
    close,
  );
  return (
    <div className="controleBlocageVente">
      <h3>Contrôler le blocage de la vente</h3>
      <p>
        Le commissaire aux ventes des Domaines a bloqué la vente de ce véhicule
        après avoir relevé la présence d’une immobilisation PJ ou d’une
        opposition dans le SIV. Le statut associé à ce blocage est :{' '}
        {dossier.statutVega}.
      </p>
      <p>
        <b>Afin de débloquer la situation :</b>
      </p>
      <ul>
        <li>
          Solliciter les équipes de l’ANTS pour que ces derniers puissent lever
          la mention bloquante.
        </li>
        <li>
          Une fois levée, cliquer sur « Relancer la vente » pour remettre à
          nouveau ce dossier aux Domaines.
        </li>
      </ul>

      <p className="consequenceActionRelance">
        <span>
          <b>Conséquence de l’action « Relancer la vente »</b>
        </span>
        <br />
        <span>
          Au clic sur « Relancer la vente », le dossier sera remis aux Domaines
          pour une nouvelle tentative de vente.
          <br></br>
          Le cas échéant, vous confirmez donc que la mention « Immobilisation PJ
          » ou « Opposition » a été retirée du dossier sur le SIV.
        </span>
      </p>
      <div className="buttonsContainer">
        <AntdButton onClick={close}>Annuler</AntdButton>
        <AntdButton type="primary" disabled={submitInProgress} onClick={submit}>
          Relancer la vente
        </AntdButton>
      </div>
    </div>
  );
};

export default ControleBlocageVente;
