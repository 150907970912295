import moment from 'moment';

import {
  ForceOrdreControllerApi,
  MainLeveeViolationDtoFormFieldEnum,
  MarqueDto,
  UpdateMainLeveeRequestDto,
} from 'lib_api/lib/api/gen';

import { UNIX_TIMESTAMP_FORMAT } from 'utils/formats';
import { MainLevee } from './types';
import { MainleveeResponse } from 'hooks/dossiers/useFetchDonneesMainlevee';
import { gradesAgent, typesRestitution } from 'utils/enumData';

export function convertMainLeveeToMainLeveeRequestDto(
  mainLevee: MainLevee,
): UpdateMainLeveeRequestDto {
  return {
    numProcesVerbal: mainLevee.numProcesVerbal || null,
    dateProcesVerbal:
      mainLevee.dateProcesVerbal?.format(UNIX_TIMESTAMP_FORMAT) || null,
    typeRestitution: mainLevee.typeRestitution
      ? typesRestitution[mainLevee.typeRestitution].requestKey
      : null,
    communeRedaction: mainLevee.communeRedaction || null,
    gradeAgent: mainLevee.gradeAgent
      ? gradesAgent[mainLevee.gradeAgent].requestKey
      : null,
    nomAgent: mainLevee.nomAgent || null,
    matriculeAgent: mainLevee.matriculeAgent || null,
    communeFonctionAgent: mainLevee.communeFonctionAgent || null,
    immatriculation: mainLevee.immatriculation || null,
    idCorrelationGenreSimplifie: mainLevee.idCorrelationGenreSimplifie || null,
    idMarque: mainLevee.idMarque || null,
    nomProprietaire: mainLevee.nomProprietaire || null,
    prenomProprietaire: mainLevee.prenomProprietaire || null,
    rsProprietaire: mainLevee.rsProprietaire || null,
    lieuAdresseProprietaire: mainLevee.lieuAdresseProprietaire || null,
    codePostalProprietaire: mainLevee.codePostalProprietaire || null,
    communeAdresseProprietaire: mainLevee.communeAdresseProprietaire || null,
    idCorrelationFourriere: mainLevee.idCorrelationFourriere || null,
    interdictionCirculer: mainLevee.interdictionCirculer || null,
    motifInterdictionCirculer: mainLevee.motifInterdictionCirculer || null,
    dateDebutPermissionProvisoire:
      mainLevee.dateDebutPermissionProvisoire?.format(UNIX_TIMESTAMP_FORMAT) ||
      null,
    dateFinPermissionProvisoire:
      mainLevee.dateFinPermissionProvisoire?.format(UNIX_TIMESTAMP_FORMAT) ||
      null,
  };
}

export function validateMainleveeField(
  dossierId: string,
  controller: ForceOrdreControllerApi,
  behaviourOnError: (error: Response, display?: boolean | undefined) => void,
): (
  field: MainLeveeViolationDtoFormFieldEnum,
  values: MainLevee,
) => Promise<void> {
  return (field, values) =>
    controller
      .validateUpdateMainLeveeFieldUsingPOST(
        field,
        dossierId,
        convertMainLeveeToMainLeveeRequestDto(values),
      )
      .catch(behaviourOnError)
      .then(values => {
        if (!values) {
          // behaviourOnError was called
          return;
        }
        const violations = values.mainLeveeViolationDtoList ?? [];
        if (violations.length > 0) {
          throw violations.map(violation => violation.message).join(', ');
        }
      });
}

export function buildDefaultMainLeveeValues(
  mainlevee: MainleveeResponse,
): MainLevee {
  return {
    numProcesVerbal: mainlevee?.procesVerbal.numero ?? undefined,
    dateProcesVerbal: mainlevee?.procesVerbal.date
      ? moment(mainlevee.procesVerbal.date)
      : undefined,
    gradeAgent: mainlevee?.agent.grade ?? undefined,
    matriculeAgent: mainlevee?.agent.matricule ?? undefined,
    nomAgent: mainlevee?.agent.nom ?? undefined,
    communeFonctionAgent: mainlevee?.agent.communeFonction ?? undefined,
    typeRestitution: mainlevee?.typeRestitution ?? undefined,
    idCorrelationGenreSimplifie:
      mainlevee?.vehicule.idCorrelationGenreSimplifie ?? undefined,
    immatriculation: mainlevee?.vehicule.immatriculation ?? undefined,
    idMarque: mainlevee?.vehicule.idMarque ?? undefined,
    nomProprietaire: mainlevee?.proprietaire.nom ?? undefined,
    prenomProprietaire: mainlevee?.proprietaire.prenom ?? undefined,
    rsProprietaire: mainlevee?.proprietaire.raisonSociale ?? undefined,
    lieuAdresseProprietaire: mainlevee?.proprietaire.adresse ?? undefined,
    communeAdresseProprietaire: mainlevee?.proprietaire.commune ?? undefined,
    codePostalProprietaire: mainlevee?.proprietaire.codePostal ?? undefined,
    idCorrelationFourriere: mainlevee?.idCorrelationFourriere ?? undefined,
    interdictionCirculer:
      mainlevee?.interdictionCirculer.hasInterdictionCirculer ?? undefined,
    dateDebutPermissionProvisoire: mainlevee?.interdictionCirculer
      .dateDebutPermissionProvisoire
      ? moment(mainlevee?.interdictionCirculer.dateDebutPermissionProvisoire)
      : undefined,
    dateFinPermissionProvisoire: mainlevee?.interdictionCirculer
      .dateFinPermissionProvisoire
      ? moment(mainlevee?.interdictionCirculer.dateFinPermissionProvisoire)
      : undefined,
    motifInterdictionCirculer:
      mainlevee?.interdictionCirculer.motifInterdictionCirculer ?? undefined,
    communeRedaction: mainlevee?.communeRedaction ?? undefined,
  };
}

/*
This function adds initialMarque to the marques list to get a fullMarques list if the initialMarque is not already present in marques or marquesPrio.
 */
export function buildFullMarques(
  marques: MarqueDto[],
  marquesPrio: MarqueDto[],
  initialMarque: MarqueDto | null,
): MarqueDto[] {
  const isInitialMarqueInMarquesPrio = !marquesPrio.some(
    marque => marque.id === initialMarque?.id,
  );
  const isInitialMarqueInMarques = !marques.some(
    marque => marque.id === initialMarque?.id,
  );
  if (
    initialMarque !== null &&
    isInitialMarqueInMarquesPrio &&
    isInitialMarqueInMarques
  ) {
    return [...marques, initialMarque];
  }
  return marques;
}

export function buildFetchProgressionInfo(
  inProgressFetchMainlevee: boolean,
  inProgressFetchMarques: boolean,
  inProgressFetchInitialMarque: boolean,
  inProgressFetchMarquesPrio: boolean,
  hasMainleveeFetchErrorOccured: boolean,
  hasInitialMarqueFetchErrorOccured: boolean,
) {
  const isDataFetchInProgress =
    inProgressFetchMainlevee || inProgressFetchInitialMarque;
  const hasDataFetchErrorOccured =
    hasMainleveeFetchErrorOccured || hasInitialMarqueFetchErrorOccured;
  const isMarquesFetchInProgress =
    inProgressFetchMarques || inProgressFetchMarquesPrio;
  return {
    isDataFetchInProgress: isDataFetchInProgress,
    hasDataFetchErrorOccured: hasDataFetchErrorOccured,
    isMarquesFetchInProgress: isMarquesFetchInProgress,
  };
}
