import { DonneesFd, DossierResponseDto } from 'lib_api/lib/api/gen';
import { useBoolean } from 'utils/genericUtils';
import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { useApi } from 'hooks/ApiStoreContext';
import {
  buildRequestValues,
  ControleDonneesVehiculeFormValues,
} from 'components/FicheDescriptiveDisplay/ControlerDonneesVehicule/utils';

export const useControleBlocageVenteErreurIdentification = (
  dossierId: string,
  setDossier: (dossier: DossierResponseDto) => void,
  closeModal: () => void,
  statutAlienation: string,
  fdData: DonneesFd | null | undefined,
): [(values: ControleDonneesVehiculeFormValues) => void, boolean] => {
  const {
    value: inProgress,
    setIsTrue: setInProgress,
    setIsFalse: setNotInProgress,
  } = useBoolean(false);

  const controller = useApi().ForceOrdreControllerApi;

  return [
    (values: ControleDonneesVehiculeFormValues) => {
      setInProgress();
      return controller
        .computeControleDonneesVehiculeBlocageVenteUsingPOST(dossierId, {
          statut: statutAlienation,
          nouvellesDonneesVehicule: buildDonneesVehicules(values, fdData),
        })
        .then(setDossier)
        .then(closeModal)
        .catch((error: Response) => {
          void backAlertMessage(error);
        })
        .finally(() => {
          setNotInProgress();
        });
    },
    inProgress,
  ];
};

function buildDonneesVehicules(
  values: ControleDonneesVehiculeFormValues,
  fdData: DonneesFd | null | undefined,
) {
  const formValues = buildRequestValues(values);
  return {
    ...formValues,
    newModele: fdData?.modele?.idCorrelation || null,
    newModeleNonReference: fdData?.modeleNonReference || false,
    newMarque: fdData?.marque?.idCorrelation || null,
    newMarqueNonReferencee: fdData?.marqueNonReferencee || false,
  };
}
