import React from 'react';
import JsonView from '@uiw/react-json-view';

import './JsonViewer.less';

interface JsonViewerProps {
  source: object;
}

const customTheme = {
  '--w-rjv-background-color': 'transparent',
  '--w-rjv-curlybraces-color': 'black',
  '--w-rjv-brackets-color': 'black',
  '--w-rjv-type-string-color': '#50a14f',
  '--w-rjv-type-date-color': '#50a14f',
  '--w-rjv-type-boolean-color': '#8c52f6',
  '--w-rjv-type-null-color': '#0184bb',
  '--w-rjv-type-nan-color': '#0184bb',
  '--w-rjv-type-undefined-color': '#0184bb',
  '--w-rjv-quotes-color': 'transparent',
  '--w-rjv-quotes-string-color': '#50a14f',
};

export default function JsonViewer({ source }: JsonViewerProps) {
  return (
    <JsonView
      value={source}
      collapsed={2}
      shortenTextAfterLength={1000}
      style={{
        flex: 1,
        ...customTheme,
      }}
    />
  );
}
