import {
  ActionsRequisesDtoActionsRequisesEnum,
  CountDossiersResultDto,
} from 'lib_api/lib/api/gen';

import { Filters as SearchFilters } from 'hooks/dossiers/useSearchDossierSummaries';

import { DashboardAdminFonctionnelFilter } from '../types';

export const actionsRequisesVentesBloquees = [
  ActionsRequisesDtoActionsRequisesEnum.RELANCE_VENTE_IMMO_PJ,
  ActionsRequisesDtoActionsRequisesEnum.RELANCE_VENTE_OPPOSITION,
  ActionsRequisesDtoActionsRequisesEnum.RELANCE_VENTE_DOUBLON,
];

export function buildSearchFilterForVenteBloquee(
  filter: Partial<DashboardAdminFonctionnelFilter>,
): SearchFilters {
  return {
    statutDossierEnumList: filter.statut,
    classementList: filter.classement,
    idNatureBrancheList: filter.natureMiseEnFourriere,
    dateRangeMiseEnFourriere: filter.dateRangeMiseEnFourriere,
    dateRangeBonEnlevement: filter.dateRangeBonEnlevement,
    dateRangeSortieDefinitive: filter.dateRangeSortieDefinitive,
    dateRangeOrdreDestruction: filter.dateRangeOrdreDestruction,
    numeroImmatriculation: filter.search,
    includeDossiersSortis: filter.includeDossiersSortis,
    idMotifSortieList: filter.motifDeSortie ?? [],
    includeDossiersAnonymises: filter.includeDossiersAnonymises,
    idFourriereList: filter.fourriere?.map(opt => opt.value as string),
    listUniteFo: filter.idUniteFOList?.map(opt => opt.value as string),
    idUniteFO: undefined,
    communeEnlevementList: undefined,
    actionsRequises: actionsRequisesVentesBloquees,
  };
}

export function libelleVentesBloquees(
  count: CountDossiersResultDto | null,
  filter: Partial<DashboardAdminFonctionnelFilter>,
): string {
  const total = count?.total ?? 0;
  const actionsRequisesFilter = filter.actionsRequises ?? [];

  if (
    (actionsRequisesFilter.length > 0 &&
      actionsRequisesVentesBloquees.some(
        actionRequise => !actionsRequisesFilter.includes(actionRequise),
      )) ||
    total === 0
  ) {
    return '';
  }

  return `dont ${total} ${total > 1 ? 'ventes bloquées' : 'vente bloquée'}`;
}
