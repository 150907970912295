import React from 'react';
import {
  Button as AntdButton,
  FormInstance as AntdFormInstance,
  FormInstance,
} from 'antd';
import { NamePath } from 'antd/lib/form/interface';

import { OptionProps } from 'types/searchResult';
import SubmitButton, { SubmitButtonProps } from './SubmitButton';
import { BaseOptionType } from './Select/BaseSelect';

// This method store in name field of antd form instance a new value
export const setFieldValue = (
  form: AntdFormInstance,
  name: NamePath & string,
  value: unknown,
): void => {
  form.setFieldsValue({
    [name]: value,
  });
};

export function atLeastOneErrorInForm<FormValues>(
  form: AntdFormInstance<FormValues>,
): boolean {
  return (
    Object.values(form.getFieldsError()).flatMap(entry => entry.errors).length >
    0
  );
}

export function buildSubmitButton(
  props: SubmitButtonProps,
): React.ReactElement {
  return <SubmitButton {...props} />;
}

export function buildCancelButton(
  onCancel: (form: FormInstance) => void,
  form: FormInstance,
  buttonLabel = 'Annuler',
): React.ReactElement {
  return (
    <AntdButton
      onClick={() => {
        onCancel(form);
      }}
    >
      {buttonLabel}
    </AntdButton>
  );
}

/* Add validation on input to accept only numeric values (for postal code per example) */
export function getValueFromEventContainingOnlyNumeric(
  event: React.ChangeEvent<HTMLInputElement>,
): string {
  const reg = new RegExp('^[0-9]*$');
  const value = event.currentTarget.value;
  if (!reg.test(value)) {
    return event.currentTarget.defaultValue;
  }
  return value;
}

/** Used to filter option using selection option label */
export function filterOption(input: string, label: string): boolean {
  //  normalize the input content
  const normalizedInput = normalizeInput(input);
  const normalizedOption = normalizeInput(label);

  //  split to get every words searched
  const words = normalizedInput.split(' ');
  //  if all words have been found, the option should be displayed
  return words.every(word => normalizedOption.includes(word));
}

//  Normalize string removing diacritics characters and force lower case
function normalizeInput(input: string): string {
  return input
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
}

export async function fetchFormViolations<
  ViolationResponseContainer,
  ViolationObject,
>(
  fetchViolations: () => Promise<ViolationResponseContainer>,
  extractViolations: (
    response: ViolationResponseContainer,
  ) => Array<ViolationObject> | null,
  extractMessage: (violation: ViolationObject) => string,
): Promise<void> {
  const response = await fetchViolations();

  const violations = extractViolations(response) ?? [];
  if (violations.length === 0) {
    return Promise.resolve();
  }

  return Promise.reject(extractMessage(violations[0]));
}

export function baseOptionTypeArrayToStringArray(
  baseOptionType: BaseOptionType<OptionProps>[] | undefined,
): string[] | undefined {
  return baseOptionType
    ?.map(option => option.value?.toString() ?? '')
    .filter(value => value !== '');
}
