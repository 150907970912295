import React, { useEffect } from 'react';

import { useFetchCountDossier } from 'hooks/dossiers/useFetchCountDossier';
import { TableSort } from 'components/WrappedComponents/Table/types';

import { DashboardAdminFonctionnelFilter } from '../types';
import Filters from './Filters';
import DownloadButton from './DownloadButton';
import {
  buildSearchFilterForVenteBloquee,
  libelleVentesBloquees,
  actionsRequisesVentesBloquees,
} from './utils';

import './DashboardHeader.less';

interface DashboardHeaderProps<SortType extends string | undefined> {
  totalResult: number;
  filter: Partial<DashboardAdminFonctionnelFilter>;
  setFilter: (value: Partial<DashboardAdminFonctionnelFilter>) => void;
  sort: TableSort<SortType>;
}

function DashboardHeader<SortType extends string | undefined>({
  totalResult,
  filter,
  setFilter,
  sort,
}: DashboardHeaderProps<SortType>): React.ReactElement {
  const countString = `${totalResult} ${
    totalResult > 1 ? 'véhicules trouvés' : 'véhicule trouvé'
  }`;

  const { object: count, fetch } = useFetchCountDossier(
    buildSearchFilterForVenteBloquee(filter),
  );
  useEffect(fetch, [filter]);

  const separator = ', ';
  const countVenteBloqueeString = libelleVentesBloquees(count, filter);

  return (
    <div>
      <div className="dashboard-header-fields">
        <Filters filter={filter} setFilter={setFilter} />
        <div className="dashboard-header-actions">
          <DownloadButton filters={filter} sort={sort} />
        </div>
      </div>
      <p className="dashboard-number-vehicules">
        {countString}
        {countVenteBloqueeString && (
          <>
            {separator}
            <span
              className="ventes-bloquees"
              onClick={() => {
                setFilter({
                  ...filter,
                  actionsRequises: actionsRequisesVentesBloquees,
                });
              }}
            >
              {countVenteBloqueeString}
            </span>
          </>
        )}
      </p>
    </div>
  );
}

export default DashboardHeader;
