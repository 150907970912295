import {
  AdminFonctionnelControllerApi,
  AutoriteFourriereControllerApi,
  ComptesUtilisateursControllerApi,
  Configuration,
  DossierControllerApi,
  ExportControllerApi,
  FetchAPI,
  FicheDescriptiveControllerApi,
  ForceOrdreControllerApi,
  GardienFourriereControllerApi,
  ManuelControllerApi,
  ReferentielControllerApi,
  RelanceVenteControllerApi,
  VersionControllerApi,
} from './gen';

export interface AuthApi {
  GardienFourriereControllerApi: GardienFourriereControllerApi;
  AutoriteFourriereControllerApi: AutoriteFourriereControllerApi;
  ForceOrdreControllerApi: ForceOrdreControllerApi;
  ReferentielControllerApi: ReferentielControllerApi;
  VersionControllerApi: VersionControllerApi;
  AdminFonctionnelControllerApi: AdminFonctionnelControllerApi;
  ExportControllerApi: ExportControllerApi;
  DossierControllerApi: DossierControllerApi;
  ComptesUtilisateursControllerApi: ComptesUtilisateursControllerApi;
  ManuelControllerApi: ManuelControllerApi;
  FicheDescriptiveControllerApi: FicheDescriptiveControllerApi;
  RelanceVenteControllerApi: RelanceVenteControllerApi;
}

export function buildAuthApi(
  fetch: FetchAPI,
  basePath: string | undefined
): AuthApi {
  const config: Configuration = { basePath };
  return {
    GardienFourriereControllerApi: new GardienFourriereControllerApi(
      config,
      '',
      fetch
    ),
    ReferentielControllerApi: new ReferentielControllerApi(config, '', fetch),
    AutoriteFourriereControllerApi: new AutoriteFourriereControllerApi(
      config,
      '',
      fetch
    ),
    ForceOrdreControllerApi: new ForceOrdreControllerApi(config, '', fetch),
    VersionControllerApi: new VersionControllerApi(config, '', fetch),
    AdminFonctionnelControllerApi: new AdminFonctionnelControllerApi(config, '', fetch),
    ExportControllerApi: new ExportControllerApi(config, '', fetch),
    DossierControllerApi: new DossierControllerApi(config, '', fetch),
    ComptesUtilisateursControllerApi: new ComptesUtilisateursControllerApi(config, '', fetch),
    ManuelControllerApi: new ManuelControllerApi(config, '', fetch),
    FicheDescriptiveControllerApi: new FicheDescriptiveControllerApi(config, '', fetch),
    RelanceVenteControllerApi: new RelanceVenteControllerApi(config, '', fetch),
  };
}
