import React, { useEffect } from 'react';
import moment from 'moment';
import {
  Button as AntdButton,
  Dropdown as AntdDropdown,
  Menu as AntdMenu,
  Tag as AntdTag,
} from 'antd';

import {
  DownOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from '@ant-design/icons';

import {
  DetailedInformationMessageDto,
  DetailedInformationMessageDtoProfilesEnum,
} from 'lib_api/lib/api/gen';

import { useDisplayInformationMessageContext } from 'hooks/DisplayMessageContext';
import { infoMessageProfileMapping } from 'utils/enumData';
import { DATE_FORMAT_WITH_TIME } from 'utils/formats';
import { FilterInformationMessage } from '../DashboardHeader/types';
import DashboardHeader from '../DashboardHeader';
import useFormModal from '../../Form/utils';
import InformationMessageForm from '../../Form/InformationMessageForm';
import {
  MessageSortOptions,
  MessageStatusInfo,
  MessageStatusMapping,
} from './types';
import {
  ColumnProps,
  TableHeaderFunction,
} from 'components/WrappedComponents/Table/types';

const messageStatus: MessageStatusMapping = {
  DISPLAYED: {
    label: 'Affiché',
    color: '#10cd00',
  },
  FUTURE: {
    label: 'À venir',
    color: '#0083cc',
  },
  OUTDATED: {
    label: 'Dépassé',
    color: '#f6d0ff',
  },
  DEACTIVATED: {
    label: 'Désactivé',
    color: '#000000',
  },
};

function ActionsContent({
  data,
  callAfterSubmit,
}: {
  data: DetailedInformationMessageDto;
  callAfterSubmit: () => void;
}): React.ReactElement {
  const { isOpen, formInitialValues, openFormModal, closeFormModal } =
    useFormModal<DetailedInformationMessageDto>();

  const menu = (
    <AntdMenu>
      <AntdMenu.Item key="modify" onClick={() => openFormModal(data)}>
        Modifier le message
      </AntdMenu.Item>
    </AntdMenu>
  );

  const now = moment();
  const endDate = moment(data.endDate);

  return (
    <>
      {endDate.isAfter(now) && (
        <>
          <AntdDropdown overlay={menu}>
            <AntdButton>
              Actions <DownOutlined />
            </AntdButton>
          </AntdDropdown>
          {isOpen && (
            <InformationMessageForm
              close={closeFormModal}
              initialValues={formInitialValues}
              callAfterSubmit={callAfterSubmit}
            />
          )}
        </>
      )}
    </>
  );
}

function getMessageStatusInfo(
  messageDto: DetailedInformationMessageDto,
): MessageStatusInfo {
  const now = moment();
  const startDate = moment(messageDto.startDate);
  const endDate = moment(messageDto.endDate);

  if (!messageDto.active) {
    return messageStatus.DEACTIVATED;
  }
  if (endDate.isBefore(now)) {
    return messageStatus.OUTDATED;
  }
  if (startDate.isBefore(now)) {
    return messageStatus.DISPLAYED;
  }

  return messageStatus.FUTURE;
}

function buildStatus(data: DetailedInformationMessageDto) {
  const { label, color } = getMessageStatusInfo(data);
  return <AntdTag color={color}>{label}</AntdTag>;
}

function buildProfile(data: DetailedInformationMessageDto) {
  return data.profiles.map((profile, index) => (
    <div key={index}>{infoMessageProfileMapping[profile]}</div>
  ));
}

function VisualisationButton({
  data,
}: {
  data: DetailedInformationMessageDto;
}): React.ReactElement {
  const { messages, addMessage, removeMessage } =
    useDisplayInformationMessageContext();

  const isOnlyBDR =
    data.profiles.length === 1 &&
    data.profiles[0] ===
      DetailedInformationMessageDtoProfilesEnum.BORD_DE_ROUTE;
  useEffect(() => {
    if (isOnlyBDR) {
      removeMessage(data.id);
    }
  }, [isOnlyBDR]);

  return isOnlyBDR ? (
    <></>
  ) : (
    <>
      {data.id in messages ? (
        <EyeInvisibleOutlined onClick={() => removeMessage(data.id)} />
      ) : (
        <EyeOutlined onClick={() => addMessage(data)} />
      )}
    </>
  );
}

export function buildColumns(
  callAfterSubmit: () => void,
): ColumnProps<DetailedInformationMessageDto, MessageSortOptions>[] {
  return [
    {
      title: 'Titre',
      buildCellContent: data => data.title,
    },
    {
      title: 'Rôle concerné',
      buildCellContent: buildProfile,
    },
    {
      title: 'Date de début',
      buildCellContent: data =>
        moment(data.startDate).format(DATE_FORMAT_WITH_TIME),
      sortedField: MessageSortOptions.START_DATE,
    },
    {
      title: 'Date de fin',
      buildCellContent: data =>
        moment(data.endDate).format(DATE_FORMAT_WITH_TIME),
      sortedField: MessageSortOptions.END_DATE,
    },
    {
      title: 'Statut',
      buildCellContent: buildStatus,
    },
    {
      title: 'Visualiser',
      buildCellContent: data => <VisualisationButton data={data} />,
    },
    {
      title: '',
      buildCellContent: data => (
        <ActionsContent data={data} callAfterSubmit={callAfterSubmit} />
      ),
    },
  ];
}

export const buildHeader: TableHeaderFunction<
  DetailedInformationMessageDto,
  FilterInformationMessage
> = (data, selectedItems, filter, setFilter) => {
  return <DashboardHeader filter={filter} setFilter={setFilter} />;
};
