import React from 'react';
import { Modal as AntdModal } from 'antd';
import { MarqueDto } from 'lib_api/lib/api/gen';
import { FieldDisplay } from 'components/FieldDisplay/FieldDisplay';
import { useFetchMarque } from 'hooks/referentiels/useFetchMarque';
import { categorieMarqueMapping, typeMarqueMapping } from 'utils/enumData';

import './ModalDetails.less';

interface ModalDetailsProps {
  marque: MarqueDto;
  visible: boolean;
  close: () => void;
}

function ModalDetails({
  marque,
  visible,
  close,
}: ModalDetailsProps): React.ReactElement {
  const [marqueExacte] = useFetchMarque(marque.marqueExacteId);

  return (
    <AntdModal
      visible={visible}
      onCancel={close}
      footer={null}
      destroyOnClose={true}
      width={980}
      maskClosable={false}
      className="modalDetails"
    >
      <FieldDisplay label="Id" value={marque.id} />
      <FieldDisplay label="Marque" value={marque.libelle} />
      <FieldDisplay
        label="Catégorie"
        value={
          marque.categorie ? categorieMarqueMapping[marque.categorie] : null
        }
      />
      <FieldDisplay
        label="Type"
        value={marque.type ? typeMarqueMapping[marque.type] : null}
      />
      {marqueExacte ? (
        <FieldDisplay
          value={marqueExacte.libelle}
          label={'Marque exacte associée'}
        />
      ) : null}
    </AntdModal>
  );
}

export default ModalDetails;
