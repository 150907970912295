import React from 'react';
import { Button as AntdButton } from 'antd';
import { useDossierContext } from 'hooks/dossiers/DossierContext';
import { extractStatutAlienationFromDossierOrThrow } from 'utils/alienationUtils';

interface ControleBlocageVenteInfosProps {
  next: () => void;
  close: () => void;
}

const ControleBlocageVenteInfos = ({
  next,
  close,
}: ControleBlocageVenteInfosProps): React.ReactElement => {
  const [dossier, setDossier] = useDossierContext();
  const statut = extractStatutAlienationFromDossierOrThrow(dossier);

  return (
    <>
      <h3>Contrôler le blocage de la vente</h3>
      <p>
        Le commissaire aux ventes des Domaines a bloqué la vente de ce véhicule
        après y avoir relevé une erreur d’identification. Le statut associé à ce
        blocage est : « {statut} »
      </p>
      <p>
        <b>
          A titre indicatif, ces cas d’incohérences entre les données saisies
          dans le SI Fourrières et les données terrain du véhicule sont
          généralement induites par :
        </b>
      </p>
      <ul>
        <li>une erreur lors de la saisie du dossier dans le SI</li>
        <li>une immatriculation maquillée</li>
        <li>une immatriculation de type “garage” non connue du SIV</li>
      </ul>
      <p>
        <b>Afin de débloquer la situation :</b>
      </p>
      <ul>
        <li>
          Vérifier les informations du véhicule et les modifier si nécessaire et
          si vous en avez connaissance.
        </li>
      </ul>
      <div className="buttonsContainer">
        <AntdButton onClick={close}>Annuler</AntdButton>
        <AntdButton type="primary" onClick={next}>
          Vérifier les informations
        </AntdButton>
      </div>
    </>
  );
};

export default ControleBlocageVenteInfos;
