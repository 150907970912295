import { MainleveeResponse } from 'hooks/dossiers/useFetchDonneesMainlevee';
import { useFetchMarque } from 'hooks/referentiels/useFetchMarque';
import { useFetchMarques } from 'pages/gardien-fourriere/FicheDescriptive/FicheVehiculeForm/Fields/utils';
import { buildFullMarques } from './utils';

export function useFullMarques(mainlevee: MainleveeResponse) {
  const [
    initialMarque,
    inProgressFetchInitialMarque,
    hasInitialMarqueFetchErrorOccured,
  ] = useFetchMarque(mainlevee?.vehicule.idMarque ?? null);
  // Retrieve marques data
  const {
    marques: marques,
    inProgress: inProgressFetchMarques,
    search: search,
    paginate: paginate,
  } = useFetchMarques(false);
  const { marques: marquesPrio, inProgress: inProgressFetchMarquesPrio } =
    useFetchMarques(true);
  const fullMarques = buildFullMarques(marques, marquesPrio, initialMarque);
  return {
    fullMarques: fullMarques,
    marquesPrio: marquesPrio,
    search: search,
    paginate: paginate,
    inProgressFetchMarques: inProgressFetchMarques,
    inProgressFetchMarquesPrio: inProgressFetchMarquesPrio,
    inProgressFetchInitialMarque: inProgressFetchInitialMarque,
    hasInitialMarqueFetchErrorOccured: hasInitialMarqueFetchErrorOccured,
  };
}
