import React from 'react';
import { Button as AntdButton } from 'antd';
import { RelanceVenteDtoRelanceVenteTypeEnum } from 'lib_api/lib/api/gen';

import { useApi } from 'hooks/ApiStoreContext';
import { useDossierContext } from 'hooks/dossiers/DossierContext';
import { extractNonnullDossierIdOrThrow } from 'utils/dossierUtils';

import { useRelanceVente } from 'hooks/dossiers/useRelanceVente';

interface RelanceVenteVolProps {
  close: () => void;
}

export const RelanceVenteVol = ({
  close,
}: RelanceVenteVolProps): React.ReactElement => {
  const [dossier, setDossier] = useDossierContext();
  const dossierId = extractNonnullDossierIdOrThrow(dossier);
  const { RelanceVenteControllerApi } = useApi();
  const [submit, submitInProgress] = useRelanceVente(
    RelanceVenteControllerApi,
    dossierId,
    setDossier,
    close,
    RelanceVenteDtoRelanceVenteTypeEnum.VOL,
    null,
  );

  return (
    <div className="relanceVenteVol">
      <h3>Contrôler le blocage de la vente</h3>
      <p>
        Le commissaire aux ventes des Domaines a bloqué la vente de ce véhicule
        après l’avoir identifié comme volé dans le SIV.
      </p>
      <p>
        <b>Afin de débloquer la situation :</b>
      </p>
      <ul>
        <li>
          Lever la mention « Vol » apposée sur le dossier dans le fichier FOVES
          (ce qui mettra à jour le SIV).
        </li>
        <li>
          Une fois levée, cliquer sur « Relancer la vente » pour remettre à
          nouveau ce dossier aux Domaines.
        </li>
      </ul>

      <p className="consequenceActionRelance">
        <span>
          <b>Conséquence de l’action « Relancer la vente »</b>
        </span>
        <br />
        <span>
          Au clic sur « Relancer la vente », le dossier sera remis aux Domaines
          pour une nouvelle tentative de vente.
          <br />
          Le cas échéant, vous confirmez donc que la mention « Vol » a été
          retirée du dossier sur le FOVES.
        </span>
      </p>
      <div className="buttonsContainer">
        <AntdButton onClick={close}>Annuler</AntdButton>
        <AntdButton type="primary" disabled={submitInProgress} onClick={submit}>
          Relancer la vente
        </AntdButton>
      </div>
    </div>
  );
};
