import { useCallback, useState } from 'react';
import {
  DetailedInformationMessageDtoProfilesEnum,
  InformationMessageRequestDto,
  InformationMessageRequestDtoUserProfileListEnum,
  InformationMessageViolationDto,
  InformationMessageViolationDtoFormFieldEnum,
  InformationMessageViolationsDto,
  ReferentielControllerApi,
} from 'lib_api/lib/api/gen';
import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { defaultErrorMessage } from 'utils/ErrorMessages';
import { useHandleBackErrors } from 'hooks/utils/handleBackErrors';
import { useApi } from 'hooks/ApiStoreContext';
import { InformationMessageFormValues } from 'types/InformationMessage/InformationMessage';
import { UNIX_TIMESTAMP_FORMAT } from 'utils/formats';

interface FormModalReturn<T> {
  isOpen: boolean;
  formInitialValues?: T;
  openFormModal: (value?: T) => void;
  closeFormModal: () => void;
}

function useFormModal<T>(): FormModalReturn<T> {
  const [isOpen, setIsOpen] = useState(false);
  const [formInitialValues, setModalInitialValues] = useState<T>();

  const openFormModal = (value?: T) => {
    setIsOpen(true);
    setModalInitialValues(value);
  };

  const closeFormModal = () => {
    setIsOpen(false);
    setModalInitialValues(undefined);
  };

  return {
    isOpen,
    formInitialValues,
    openFormModal,
    closeFormModal,
  };
}

export default useFormModal;

export enum InformationMessageFormType {
  CREATE,
  UPDATE,
}

export function generateRequestDtoFromFormData(
  values: InformationMessageFormValues,
): InformationMessageRequestDto {
  return {
    title: values.title || null,
    content: values.content || null,
    startDate: values.startDate?.format(UNIX_TIMESTAMP_FORMAT) || null,
    endDate: values.endDate?.format(UNIX_TIMESTAMP_FORMAT) || null,
    active: values.active || false,
    userProfileList: values.profiles || null,
  };
}

type UserConversion = {
  [key in DetailedInformationMessageDtoProfilesEnum]: InformationMessageRequestDtoUserProfileListEnum;
};

export const userConversionMapping: UserConversion = {
  AUTORITE_FOURRIERE:
    InformationMessageRequestDtoUserProfileListEnum.AUTORITE_FOURRIERE,
  GARDIEN_FOURRIERE:
    InformationMessageRequestDtoUserProfileListEnum.GARDIEN_FOURRIERE,
  FORCE_DE_L_ORDRE:
    InformationMessageRequestDtoUserProfileListEnum.FORCE_DE_L_ORDRE,
  BORD_DE_ROUTE: InformationMessageRequestDtoUserProfileListEnum.BORD_DE_ROUTE,
};

/**
 * Validate create or update information message request
 *
 * @param controller Controller used to validate fields
 * @param values Request data sent to API
 * @param field FIeld to validate
 */
export async function validateInformationMessageRequest(
  controller: ReferentielControllerApi,
  values: InformationMessageRequestDto,
  field: InformationMessageViolationDtoFormFieldEnum,
): Promise<void> {
  try {
    const response: InformationMessageViolationsDto =
      await controller.informationMessageValidateFieldUsingPOST1(field, values);

    if (response.informationMessageViolationDtos) {
      const fieldViolations = response.informationMessageViolationDtos.filter(
        violation => violation.formField === field,
      );

      if (fieldViolations.length > 0) {
        return Promise.reject(
          fieldViolations.map(violation => violation.message).join(', '),
        );
      }
    }
  } catch (e) {
    if (e instanceof Response) {
      return Promise.reject((await backAlertMessage(e)).description);
    }
    return Promise.reject(defaultErrorMessage);
  }
  return Promise.resolve();
}

/**
 * Hook used to submit a request to create or update information message
 *
 * @param id
 * @param thenSubmit function called if submit success
 */
export function useCreateOrUpdateInformationMessage(
  thenSubmit: () => void,
  id?: string,
): [
  (createFormValues: InformationMessageFormValues) => Promise<Response>,
  () => void,
  (errorResponse: Response) => void,
] {
  const behaviourOnError = useHandleBackErrors();
  const controller = useApi().ReferentielControllerApi;

  const submitCreateUser = useCallback(
    (
      modificationFormValues: InformationMessageFormValues,
    ): Promise<Response> => {
      const creationRequest = generateRequestDtoFromFormData(
        modificationFormValues,
      );
      return id
        ? controller.updateInformationMessageUsingPOST(id, creationRequest)
        : controller.createInformationMessageUsingPOST(creationRequest);
    },
    [controller, id],
  );

  const onSuccess = useCallback((): void => {
    thenSubmit();
  }, [thenSubmit]);

  const onError = useCallback(
    (errorResponse: Response): void => {
      behaviourOnError(errorResponse);
    },
    [behaviourOnError],
  );

  return [submitCreateUser, onSuccess, onError];
}

export type InformationMessageValidationCallback<T> = (
  controller: T,
) => Promise<InformationMessageViolationsDto>;

export function useValidationInformationMessageRequest<
  Controller,
  Key extends keyof InformationMessageViolationsDto,
  PromiseReturnType,
>(
  controller: Controller,
  validationKey: Key,
  promiseOnSuccess: () => Promise<PromiseReturnType>,
  thenPromiseOnsuccess?: (data: PromiseReturnType) => void,
  catchPromiseOnSuccess?: (errorResponse: Response) => void,
): [
  (
    requestGenerator: InformationMessageValidationCallback<Controller>,
  ) => Promise<void>,
  InformationMessageViolationsDto[Key] | null,
] {
  const [violations, setViolations] = useState<
    InformationMessageViolationsDto[Key] | null
  >(null);

  const onSubmit = useCallback(
    async (
      requestGenerator: (
        controller: Controller,
      ) => Promise<InformationMessageViolationsDto>,
    ) => {
      try {
        const violationResponse = await requestGenerator(controller);
        const violations: InformationMessageViolationsDto[Key] | null =
          violationResponse[validationKey];

        setViolations(violations);
        if (violations !== null && violations.length === 0) {
          await promiseOnSuccess()
            .then(thenPromiseOnsuccess)
            .catch(catchPromiseOnSuccess);
        }
      } catch (e) {
        if (e instanceof Response) {
          return Promise.reject((await backAlertMessage(e)).description);
        }
        return Promise.reject(defaultErrorMessage);
      }
      return Promise.resolve();
    },
    [
      controller,
      validationKey,
      promiseOnSuccess,
      thenPromiseOnsuccess,
      catchPromiseOnSuccess,
    ],
  );

  return [onSubmit, violations];
}

type CreateOrUpdateInformationMessageCallback =
  InformationMessageValidationCallback<ReferentielControllerApi>;

/**
 * Hook used to validate and submit a request to create or update information message
 *
 * @param onSuccess Function called if validation succeed
 * @param thenSubmit Function called if promise called in onSuccess succeed
 * @param catchSubmit Function called if promise called in onSuccess fails
 */
export function useValidateCreateUpdateInformationMessage(
  onSuccess: () => Promise<Response>,
  thenSubmit: () => void,
  catchSubmit: (errorResponse: Response) => void,
): [
  (requestGenerator: CreateOrUpdateInformationMessageCallback) => Promise<void>,
  InformationMessageViolationDto[] | null,
] {
  const controller = useApi().ReferentielControllerApi;
  return useValidationInformationMessageRequest(
    controller,
    'informationMessageViolationDtos',
    onSuccess,
    thenSubmit,
    catchSubmit,
  );
}
