import { useCallback, useEffect, useState } from 'react';

import { useApi } from 'hooks/ApiStoreContext';
import { useHandleBackErrors } from 'hooks/utils/handleBackErrors';
import { useEnvironnement } from 'hooks/EnvironementStoreContext';
import { CamundaProcessInstanceDto } from 'lib_api/lib/api/gen';

export interface ProcessInstanceUrl {
  url: string;
}

interface FetchProcessInstancesResult {
  processInstances: ProcessInstanceUrl[];
  fetchInstances: () => Promise<void>;
  fetchInProgress: boolean;
  errorOccured: boolean;
}

export default function useFetchProcessInstances(
  dossierId: string,
): FetchProcessInstancesResult {
  const [env] = useEnvironnement();
  const [processInstances, setProcessInstances] = useState<
    ProcessInstanceUrl[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorOccured, setErrorOccured] = useState<boolean>(false);
  const { AdminFonctionnelControllerApi } = useApi();
  const behaviourOnError = useHandleBackErrors();

  const mapUrl = (
    processInstance: CamundaProcessInstanceDto,
  ): ProcessInstanceUrl => {
    return {
      url: `${env.CAMUNDA_URL}${env.CAMUNDA_COCKPIT_PATH}#/process-instance/${processInstance.processInstanceId}`,
    };
  };

  const fetchAndSetProcessInstances = useCallback(async () => {
    setProcessInstances([]);
    setErrorOccured(false);
    setLoading(true);

    await AdminFonctionnelControllerApi.getProcessInstancesUsingGET(dossierId)
      .then(result => {
        setProcessInstances(result.map(mapUrl));
      })
      .catch(errorResponse => {
        setErrorOccured(true);
        behaviourOnError(errorResponse);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [AdminFonctionnelControllerApi, dossierId, behaviourOnError]);

  // Fetch process instances
  useEffect(() => {
    void fetchAndSetProcessInstances();
  }, [AdminFonctionnelControllerApi, dossierId, fetchAndSetProcessInstances]);

  return {
    processInstances,
    fetchInProgress: loading,
    errorOccured: errorOccured,
    fetchInstances: fetchAndSetProcessInstances,
  };
}
