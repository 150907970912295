import React from 'react';

import {
  Button as AntdButton,
  Dropdown as AntdDropdown,
  Space as AntdSpace,
  MenuProps,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

import { ProcessInstanceUrl } from 'hooks/dossierAdmin/useFetchProcessInstances';

interface AvailableProcessInstancesDropdownProps {
  processInstances: ProcessInstanceUrl[];
}

const AvailableProcessInstancesDropdown: React.FC<
  AvailableProcessInstancesDropdownProps
> = ({
  processInstances,
}: AvailableProcessInstancesDropdownProps): React.ReactElement => {
  const mapProcessInstanceMenuProps = (
    processInstance: ProcessInstanceUrl,
    index: number,
  ): ItemType => {
    return {
      label: (
        <a href={processInstance.url} target="_blank" rel="noreferrer">
          Voir l&apos;instance Camunda {index + 1}
        </a>
      ),
      key: index,
    };
  };

  const items: MenuProps['items'] = processInstances.map(
    mapProcessInstanceMenuProps,
  );

  const menuProps = { items };

  return (
    <AntdDropdown menu={menuProps}>
      <AntdButton shape="round">
        <AntdSpace>
          Voir les instances Camunda
          <DownOutlined />
        </AntdSpace>
      </AntdButton>
    </AntdDropdown>
  );
};

export default AvailableProcessInstancesDropdown;
