import {
  DossierResponseDto,
  RelanceVenteControllerApi,
  RelanceVenteDtoRelanceVenteTypeEnum,
} from 'lib_api/lib/api/gen';
import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { useBoolean } from 'utils/genericUtils';

export function useRelanceVente(
  controller: RelanceVenteControllerApi,
  dossierId: string,
  setDossier: (dossier: DossierResponseDto) => void,
  closeModal: () => void,
  relanceVenteType: RelanceVenteDtoRelanceVenteTypeEnum,
  statut: string | null,
): [() => void, boolean] {
  const {
    value: inProgress,
    setIsTrue: setInProgress,
    setIsFalse: setNotInProgress,
  } = useBoolean(false);
  return [
    () => {
      setInProgress();
      return controller
        .relanceVenteUsingPOST({
          dossierId: dossierId,
          relanceVenteType: relanceVenteType,
          statut: statut,
        })
        .then(setDossier)
        .then(closeModal)
        .catch((error: Response) => {
          void backAlertMessage(error);
        })
        .finally(() => {
          setNotInProgress();
        });
    },
    inProgress,
  ];
}
